import { useMutation } from '@apollo/react-hooks'
import { Button, Form } from 'antd'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import ScheduleDayField from 'components/Fields/ScheduleDayField'
import { handleGraphQLErrors } from 'utils/error'

import { RESCHEDULE_TOUR_REQUEST } from './mutations'
import styles from './styles.module.scss'

function RescheduleTourRequest(props) {
  const { publicToken, availableDate } = props
  const history = useHistory()
  const [form] = Form.useForm()

  const [rescheduleTourRequest, { loading: mutationLoading }] = useMutation(
    RESCHEDULE_TOUR_REQUEST,
    {
      onCompleted: () =>
        history.push(`/reschedule-tour-request/${publicToken}/finished`),
      onError: (error) =>
        handleGraphQLErrors({
          ...error,
          form,
          defaultMessage:
            'An error occurred. Please contact leasing@darwinhomes.com for help.',
        }),
    }
  )

  return (
    <div className={styles.reschedule}>
      <Form
        form={form}
        scrollToFirstError
        colon={false}
        layout="vertical"
        size="large"
        noValidate
        hideRequiredMark
      >
        <div className={styles.rescheduleTour}>Reschedule Tour</div>
        <div className={styles.tour}>
          You can tour the home anytime between 8AM and 8PM
        </div>
        <ScheduleDayField availableDate={availableDate} />
      </Form>
      <div className={styles.buttonContainer}>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          onClick={() =>
            handleSubmit({ form, rescheduleTourRequest, publicToken })
          }
          loading={mutationLoading}
          disabled={mutationLoading}
          className={styles.button}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

async function handleSubmit({ form, rescheduleTourRequest, publicToken }) {
  try {
    const values = await form.validateFields()

    rescheduleTourRequest({
      variables: {
        input: {
          publicToken,
          ...values,
        },
      },
    })
  } catch {
    // Catch block left intentionally empty
  }
}

RescheduleTourRequest.propTypes = {
  publicToken: PropTypes.string.isRequired,
  availableDate: PropTypes.string,
}

export default RescheduleTourRequest
