export function validatePhoneNumber(rule, value) {
  if (!value) {
    return Promise.resolve()
  }
  value = value.replace(/\D/g, '')
  if (value[0] in ['0', '1']) {
    return Promise.reject(
      new Error(`Valid phone numbers cannot start with a ${value[0]}`)
    )
  } else if (value.length > 0 && value.length !== 10) {
    return Promise.reject(new Error('Please input a 10 digit phone number'))
  } else {
    return Promise.resolve()
  }
}
