import { useQuery } from '@apollo/react-hooks'
import { Button } from 'antd'
import storage from 'local-storage-fallback'
import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import Container from 'components/Container'
import ErrorPanel from 'components/ErrorPanel'
import PageLoader from 'components/PageLoader'
import { GET_PUBLIC_UNIT } from 'graphql/queries'
import Page from 'pages/Page'
import { track } from 'utils'

import styles from './styles.module.scss'

export default function TourRequestInquiryLandingPage() {
  const { slug } = useParams()
  const history = useHistory()

  const { loading, data, error } = useQuery(GET_PUBLIC_UNIT, {
    variables: { slug },
    skip: !slug,
  })

  const {
    id: unitId,
    fullAddress,
    matterportUrl,
    market,
    activeListing,
    isSelfShowing,
  } = data?.publicUnit || {}

  const { id: listingId } = activeListing || {}

  const marketName = market?.name

  const tourRequestSource = storage.getItem('tourRequestSource')

  useEffect(() => {
    track.event('inquiry_intro_page_view', {
      listingId,
      unitId,
      fullAddress,
      market: marketName,
      walkthrough: !!matterportUrl,
      tourRequestSource,
    })
  }, [
    listingId,
    unitId,
    fullAddress,
    marketName,
    matterportUrl,
    tourRequestSource,
  ])

  const title = activeListing
    ? `Thank you for your interest in ${fullAddress}!`
    : `Oh no! Unfortunately, ${fullAddress} is unavailable`

  if (activeListing && !isSelfShowing) {
    window.location.assign(`/residents/units/${slug}`)
  }

  let steps
  if (activeListing) {
    if (matterportUrl) {
      steps = (
        <>
          <div className={styles.step}>Step 1</div>
          <div className={styles.info}>
            Complete a 3D walkthrough in order to tour or apply to this home.
          </div>
          <div className={styles.step}>Step 2</div>
          <div className={styles.info}>
            Tour or apply to this home.
            <br />
            <br />
            If touring the home, we will ask you a few short questions.
            Don&apos;t worry, this should only take a few minutes!
            <br />
            <br />
            If submitting an application, you can expect a response from a
            member of our team within 48 hours once the application is fully
            submitted.
          </div>
        </>
      )
    } else {
      steps = (
        <div className={styles.info}>
          Click next to tour or apply to this home.
          <br />
          <br />
          If touring the home, we will ask you a few short questions. Don&apos;t
          worry, this should only take a few minutes!
          <br />
          <br />
          If submitting an application, you can expect a response from a member
          of our team within 48 hours once the application is fully submitted.
        </div>
      )
    }
  } else {
    steps = (
      <div className={styles.info}>
        Good news is that we have some more homes that we think you will like!
      </div>
    )
  }

  const handleButtonClick = activeListing
    ? () => {
        track.event('tour_request_inquiry_intro_page_next_click')

        storage.setItem(`listing${listingId}Step`, 'walkthrough')
        history.push({
          pathname: `/tour-request/${slug}/walkthrough`,
          search: marketName ? `${`?market=${marketName}`}` : '',
        })
      }
    : () => {
        track.event('inquiry_intro_page_home_unavailable_see_other_homes_click')
        window.location.assign(
          `/residents/rentals${marketName ? `?market=${marketName}` : ''}`
        )
      }

  return (
    <Page
      id="tour-request-inquiry"
      title="Darwin Homes | Tour Request Inquiry"
      description="Darwin Homes Tour Request Inquiry Page"
      className={styles.page}
    >
      <div className={styles.header} />
      <Container>
        {loading && <PageLoader />}
        {error && (
          <ErrorPanel
            error={error}
            trackingInfo={{
              page: 'Tour Request Inquiry Landing Page',
              unitId,
            }}
          />
        )}
        {data && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>{title}</div>
              {matterportUrl && (
                <div className={styles.info}>
                  Please read through the steps below and click next.
                </div>
              )}
              <div className={styles.steps}>{steps}</div>
              <div className={styles.buttonContainer}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  onClick={handleButtonClick}
                  className={styles.button}
                >
                  {activeListing ? 'Next' : 'Check out more homes'}
                </Button>
              </div>
            </div>
          </>
        )}
      </Container>
    </Page>
  )
}
