interface LogoProps {
  className?: string
  variant?: 'black' | 'white'
}

export default function Logo({
  className,
  variant = 'black',
}: LogoProps): JSX.Element {
  return (
    <svg
      width="110"
      height="28"
      viewBox="0 0 110 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.6449 13.4401C23.6449 4.8054 19.1461 0 11.7232 0H0V2.45548V11.667L3.38824 9.45706V3.56651H11.5358C17.759 3.56651 19.8615 8.25927 19.8615 13.4401C19.8615 17.8643 17.4599 21.9052 13.241 22.9911C12.5195 23.1768 12.4446 23.2761 11.5358 23.2761H9.80814V26.8426H11.7982C12.5561 26.8426 12.5471 26.7929 13.241 26.6945C19.3428 25.8287 23.6449 21.1931 23.6449 13.4401Z"
        fill={variant}
      />
      <path
        d="M9.80814 23.2761H11.5358C12.4446 23.2761 12.5195 23.1768 13.241 22.9911V15.8378L6.62049 11.2952L3.38824 13.5051V17.4339L6.62049 15.3467L9.80814 17.4339V23.2761Z"
        fill={variant}
      />
      <path
        d="M0 15.8378V26.8426H3.38824V23.2761V17.4339V13.5051L0 15.8378Z"
        fill={variant}
      />
      <path
        d="M39.5386 10.0263C38.5301 8.52444 36.6626 7.13522 33.6744 7.13522C28.8187 7.13522 25.1582 10.965 25.1582 17.2728C25.1582 23.5806 28.8187 27.3728 33.6744 27.3728C36.6626 27.3728 38.5301 25.946 39.5386 24.5943H39.6133V26.8471H43.3112V7.69842H39.6133V10.0263H39.5386ZM34.272 24.3315C30.6863 24.3315 28.9681 21.0274 28.9681 17.2728C28.9681 13.5181 30.6863 10.214 34.272 10.214C37.559 10.214 39.6133 12.9174 39.6133 17.2728C39.6133 21.5906 37.559 24.3315 34.272 24.3315Z"
        fill={variant}
      />
      <path
        d="M46.5698 7.69842V26.8471H50.2303V16.9349C50.2303 13.2928 52.6208 10.7772 55.9451 10.7772C56.356 10.7772 56.6922 10.8148 57.1777 10.8899V7.3605C56.6175 7.28541 56.0945 7.24786 55.4222 7.24786C52.8449 7.24786 51.2762 8.93745 50.305 11.3404H50.2303V7.69842L46.5698 7.69842Z"
        fill={variant}
      />
      <path
        d="M57.5314 7.69842L63.433 26.8471H67.2055L71.3142 12.0914H71.3889L75.4976 26.8471H79.2702L85.2091 7.69842L81.3619 7.69842L77.3279 22.5293H77.2158L73.2565 7.69842L69.5587 7.69842L65.5994 22.5293H65.5247L61.4907 7.69842L57.5314 7.69842Z"
        fill={variant}
      />
      <path
        d="M86.4668 0.301758V4.28169L90.6128 4.28169V0.301758L86.4668 0.301758ZM86.7283 7.69842V26.8471H90.3887V7.69842L86.7283 7.69842Z"
        fill={variant}
      />
      <path
        d="M93.6395 26.8471H97.3V15.0951C97.3 12.204 99.317 10.2891 102.455 10.2891C105.181 10.2891 106.302 12.0163 106.302 14.5694V26.8471H110V13.5557C110 9.16273 107.161 7.13522 103.65 7.13522C100.363 7.13522 98.3832 8.63708 97.3747 10.1014H97.3V7.69842L93.6395 7.69842V26.8471Z"
        fill={variant}
      />
    </svg>
  )
}
