import { Client } from 'persona'

import { IS_PROD } from 'constants/env'

const environment = IS_PROD ? 'production' : 'sandbox'

export default function usePersona(templateId, clientOptions = {}) {
  return new Client({
    templateId,
    environment,
    ...clientOptions,
  })
}
