import { Input, Form } from 'antd'
import PropTypes from 'prop-types'

import FieldLabel from 'components/AccessFormFields/FieldLabel'
import LockboxSerialField from 'components/AccessFormFields/LockboxSerialField'
import PhoneNumberField from 'components/Fields/PhoneNumberField'

function VendorFormFields(props) {
  const { form } = props

  return (
    <>
      <FieldLabel
        label="Lockbox Serial Number"
        tooltip="The lockbox serial number is either a six or ten digit code located on the front of the lockbox. Please include the 0s"
      >
        <LockboxSerialField form={form} />
      </FieldLabel>
      <FieldLabel label="Work Order #">
        <Form.Item
          form={form}
          name="workOrderNumber"
          rules={[
            { required: true, message: 'Please enter your work order #' },
          ]}
          validateTrigger="onBlur"
        >
          <Input size="large" placeholder="Work Order #" />
        </Form.Item>
      </FieldLabel>
      <FieldLabel label="Phone Number">
        <PhoneNumberField placeholder="(000) 000-0000" form={form} required />
      </FieldLabel>
    </>
  )
}

VendorFormFields.propTypes = { form: PropTypes.object.isRequired }

export default VendorFormFields
