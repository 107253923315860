import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

function FieldLabel(props) {
  const { label, children, tooltip } = props

  return (
    <>
      <div className={styles.label}>
        {label}{' '}
        {tooltip && (
          <Popover
            content={tooltip}
            title="Lockbox Serial Number"
            overlayClassName={styles.overlay}
            trigger="click"
          >
            <QuestionCircleOutlined />
          </Popover>
        )}
      </div>
      {children}
    </>
  )
}

FieldLabel.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
}

export default FieldLabel
