import Checkbox from '@darwin-homes/checkbox'
import FormItem from '@darwin-homes/form-item'
import Modal from '@darwin-homes/modal'
import MoneyInput from '@darwin-homes/money-input'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'
import { useState } from 'react'

import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './IncomeStep.module.scss'

export default function IncomeStep({
  setStep,
  stepName,
}: TourRequestStepProps): JSX.Element {
  const { values } = useFormikContext<TourRequestForm>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [hasTriggeredModal, setHasTriggeredModal] = useState(false)

  const handleClickNext = () => {
    const hasCosigner = values.hasCosigner
    const annualIncomeAmount = values.annualIncomeAmount
    if (
      !hasTriggeredModal &&
      !hasCosigner &&
      annualIncomeAmount &&
      annualIncomeAmount < 1000000
    ) {
      setHasTriggeredModal(true)
      setIsModalVisible(true)
      return
    }

    storage.setItem('hasCosigner', hasCosigner ? 'true' : 'false')
    storage.setItem('annualIncomeAmount', annualIncomeAmount.toString())
    setStep('credit-score')
  }

  const handleClickBack = () => {
    const numPets = storage.getItem('numPets')
    if (numPets && parseInt(numPets) > 0) setStep('pet-categories')
    else setStep('number-of-pets')
  }

  return (
    <Step
      stepLabel="What is your yearly household income?"
      stepInfo="Please include you and your roommates' estimated combined annual income"
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
      stepName={stepName}
      fieldNames={['annualIncomeAmount']}
    >
      <FormItem name="annualIncomeAmount" className={styles.income}>
        <MoneyInput />
      </FormItem>
      <FormItem name="hasCosigner" className={styles.checkboxItem}>
        <Checkbox
          label="I plan to apply with a co-signer or guarantor who will be financially
            responsible for the lease. A co-signer is not a roommate and will not
            be living in this home."
          checked={values.hasCosigner}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      <Modal
        open={isModalVisible}
        heading="It looks like you may have entered monthly income."
        subheading="Please ensure that
          you are listing you and your roommates' combined yearly income. e.g. $40,000 per year."
        handleClose={() => setIsModalVisible(false)}
        className={styles.modal}
      />
    </Step>
  )
}
