import { Form, Radio } from 'antd'
import range from 'lodash/range'
import moment from 'moment'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

function ScheduleDayField(props) {
  const { requestedTourDate, availableDate } = props

  const days = range(8).map((i) =>
    moment.max(moment(availableDate), moment()).add(i, 'days')
  )

  return (
    <Form.Item
      name="requestedTourDate"
      initialValue={requestedTourDate}
      rules={[
        { required: true, message: 'Please choose a day to request a tour' },
      ]}
    >
      <Radio.Group className={styles.requestedTourDate}>
        {days.map((day) => {
          const formattedDay = day.format('YYYY-MM-DD')
          return (
            <Radio.Button value={formattedDay} key={formattedDay}>
              <div>{day.format('dddd')}</div>
              <div>{day.format('MMMM D, YYYY')}</div>
            </Radio.Button>
          )
        })}
      </Radio.Group>
    </Form.Item>
  )
}

ScheduleDayField.propTypes = {
  requestedTourDate: PropTypes.string,
  availableDate: PropTypes.string,
}

export default ScheduleDayField
