import PropTypes from 'prop-types'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Container from 'components/Container'
import { pathIncludesOneOf } from 'utils/path'

import FooterFloor from './FooterFloor'
import FooterNav from './FooterNav'
import styles from './styles.module.css'

class Footer extends Component {
  render() {
    const { location } = this.props
    const { pathname } = location

    const pathsToExclude = [
      '/tour-request',
      '/tour-feedback',
      '/reschedule-tour-request',
      '/access',
    ]

    if (pathIncludesOneOf(pathname, pathsToExclude)) {
      return null
    }

    return (
      <div className={styles.section}>
        <Container className={styles.wrapper}>
          <FooterNav menu={this.props.menu} />
          <div style={{ borderBottom: '1px solid #C4C4C4', opacity: 0.2 }} />
          <FooterFloor />
        </Container>
      </div>
    )
  }
}

Footer.propTypes = {
  menu: PropTypes.array.isRequired,
}

export default withRouter(Footer)
