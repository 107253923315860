export function formatMoney(money?: number | null, showCents = false) {
  if (money === null || money === undefined) return money

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showCents ? 2 : 0,
    maximumFractionDigits: showCents ? 2 : 0,
  }).format(money / 100)
}
