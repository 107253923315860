import gql from 'graphql-tag'

import { publicUnitVariables, publicUnit } from './__generated__/publicUnit'

export type Variables = publicUnitVariables
export type Response = publicUnit

export const Query = gql`
  query publicUnit($slug: String!) {
    publicUnit(slug: $slug) {
      id
      fullAddress
      numBedrooms
      numBathrooms
      sqFootage
      petsAllowed
      isUnitAvailable
      slug
      activeListing {
        id
        availableDate
        rentAmount
        isSelfShowing
        earliestSchedulableSelfTourDate
      }
      market {
        id
        name
      }
    }
  }
`
