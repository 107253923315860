import gql from 'graphql-tag'

export const RESEND_TOUR_REQUEST_SMS = gql`
  mutation resendTourRequestSms($input: ResendTourRequestSmsInput!) {
    resendTourRequestSms(input: $input) {
      tourRequest {
        id
        publicToken
      }
    }
  }
`
