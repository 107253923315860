import { ComponentType } from 'react'

import styles from './IconHeader.module.scss'

interface IconHeaderProps {
  icon: ComponentType<{ className?: string }>
  title: string
  subtitle?: string
}

export default function IconHeader({
  icon: Icon,
  title,
  subtitle,
}: IconHeaderProps): JSX.Element {
  return (
    <div className={styles.iconHeader}>
      <Icon />
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{subtitle}</div>
    </div>
  )
}
