import { ComponentType } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, Route, Switch } from 'react-router-dom'

import Footer from 'components/Footer'
import Header from 'components/Header'
import AccessSuccessPage from 'pages/AccessSuccessPage'
import InquiryLandingPage from 'pages/InquiryLandingPage'
import NotFoundPage from 'pages/NotFoundPage'
import RescheduleTourRequestPage from 'pages/RescheduleTourRequestPage'
import TourFeedbackFinishedPage from 'pages/TourFeedback/TourFeedbackFinishedPage'
import TourFeedbackPage from 'pages/TourFeedback/TourFeedbackPage'
import TourRequestAccessPage from 'pages/TourRequestAccessPage'
import TourRequestFinishedPage from 'pages/TourRequestFinishedPage'
import TourRequestInquiryLandingPage from 'pages/TourRequestInquiryLandingPage'
import TourRequestWizardPage from 'pages/TourRequestWizardPage'
import VendorAccessPage from 'pages/VendorAccessPage'

interface Page {
  path: string
  component?: ComponentType & { menuTitle?: string }
  menuTitle?: string
  isToNewLanding?: boolean
}

function menuItem(page: Page) {
  return {
    path: page.path,
    menuTitle: page.menuTitle || page.component?.menuTitle || page.path,
    isToNewLanding: page.isToNewLanding,
  }
}

function subMenu(title: string, items: Page[]) {
  return {
    menuTitle: title,
    items,
  }
}

// Owners submenu
const ownersServices = {
  path: '/owners/services',
  menuTitle: 'Services',
  isToNewLanding: true,
}
const ownersResources = {
  path: '/owners/resources',
  menuTitle: 'Resources',
  isToNewLanding: true,
}
const ownersPricing = {
  path: '/owners/pricing',
  menuTitle: 'Pricing',
  isToNewLanding: true,
}
const ownersTeam = {
  path: '/owners/team',
  menuTitle: 'Team',
  isToNewLanding: true,
}
const referrals = {
  path: '/partners',
  menuTitle: 'Referrals',
  isToNewLanding: true,
}
const ownerPortal = {
  path: process.env.REACT_APP_OWNER_PORTAL_URI,
  menuTitle: 'Owner Portal',
}

// Residents submenu
const residentsServices = {
  path: '/residents/services',
  menuTitle: 'Services',
  isToNewLanding: true,
}
const residentsResources = {
  path: '/residents/resources',
  menuTitle: 'Resources',
  isToNewLanding: true,
}
const rentals = {
  path: '/residents/rentals',
  menuTitle: 'Find a Home',
  isToNewLanding: true,
}
const residentPortal = {
  path: process.env.REACT_APP_RESIDENT_PORTAL_URI,
  menuTitle: 'Resident Portal',
}

// Company submenu
const aboutUs = {
  path: '/about-us',
  menuTitle: 'About Us',
  isToNewLanding: true,
}
const blog = {
  path: process.env.REACT_APP_BLOG_URL,
  menuTitle: 'Blog',
}
const careers = {
  path: 'https://jobs.lever.co/darwinhomes',
  menuTitle: 'Careers',
}

const headerMenu = [
  subMenu('Owners', [
    menuItem(ownersServices),
    menuItem(ownersResources),
    menuItem(ownersPricing),
    menuItem(ownersTeam),
    menuItem(ownerPortal),
  ]),
  subMenu('Residents', [
    menuItem(residentsServices),
    menuItem(residentsResources),
    menuItem(rentals),
    menuItem(residentPortal),
  ]),
  subMenu('Company', [menuItem(aboutUs), menuItem(blog), menuItem(careers)]),
]

const footerMenu = [
  subMenu('Owners', [
    menuItem(ownersServices),
    menuItem(ownersResources),
    menuItem(ownersPricing),
    menuItem(ownersTeam),
    menuItem(referrals),
    menuItem(ownerPortal),
  ]),
  subMenu('Residents', [
    menuItem(residentsServices),
    menuItem(residentsResources),
    menuItem(rentals),
    menuItem(residentPortal),
  ]),
  subMenu('Company', [menuItem(aboutUs), menuItem(blog), menuItem(careers)]),
]

export default function App(): JSX.Element {
  return (
    <div style={{ overflow: 'hidden', height: '100%' }}>
      <Helmet defaultTitle="Darwin Homes">
        <title>Next-Generation Property Management</title>
        <meta
          name="description"
          content="Maintenance, Evolved. Property Managers, you finally have one destination for all your maintenance needs. Focus on growing your business and let Darwin take care of the rest."
        />
      </Helmet>
      <Header menu={headerMenu} />
      <Switch>
        <Route path="/inquiry-landing-page" component={InquiryLandingPage} />
        <Route
          path="/tour-request/inquiry/:slug"
          component={TourRequestInquiryLandingPage}
        />
        <Route
          path="/tour-request/finished"
          component={TourRequestFinishedPage}
        />
        <Route
          path="/reschedule-tour-request/:publicToken/finished"
          render={(props) => (
            <RescheduleTourRequestPage isRescheduled {...props} />
          )}
        />
        <Route
          path="/reschedule-tour-request/:publicToken"
          component={RescheduleTourRequestPage}
        />
        <Route
          path="/tour-request/:slug/:step"
          component={TourRequestWizardPage}
        />
        <Route
          path="/tour-feedback/:publicToken/finished"
          component={TourFeedbackFinishedPage}
        />
        <Route
          path="/tour-feedback/:publicToken"
          component={TourFeedbackPage}
        />
        <Route path="/access/:type/success" component={AccessSuccessPage} />
        <Route path="/access/tour" component={TourRequestAccessPage} />
        <Route path="/access/vendor" component={VendorAccessPage} />
        <Redirect from="/access/:any" to="/access/tour" />
        <Route component={NotFoundPage} key="NotFoundPage" />
      </Switch>
      <Footer menu={footerMenu} />
    </div>
  )
}
