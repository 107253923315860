import { Form } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import MaskedInput from 'components/MaskedInput'
import { validatePhoneNumber } from 'utils/phone'

import styles from './styles.module.css'

function PhoneNumberField(props) {
  const {
    form,
    name,
    formatFieldName,
    required,
    initialValue,
    label,
    className,
    inputStyle,
    onChange,
    placeholder,
  } = props

  return (
    <>
      <Form.Item
        label={label}
        className={classNames(styles.input, className)}
        name={formatFieldName(name)}
        rules={[
          { required, message: 'Please enter a phone number' },
          { validator: validatePhoneNumber },
        ]}
        validateTrigger="onBlur"
        initialValue={initialValue}
        form={form}
      >
        <MaskedInput
          mask="299-999-9999"
          placeholder={placeholder}
          type="tel"
          size="large"
          className={classNames(styles.input, inputStyle)}
          onChange={onChange}
        />
      </Form.Item>
    </>
  )
}

PhoneNumberField.propTypes = {
  form: PropTypes.object.isRequired,
  numFieldGroups: PropTypes.number,
  index: PropTypes.number,
  onRemove: PropTypes.func,
  name: PropTypes.string,
  formatFieldName: PropTypes.func,
  required: PropTypes.bool,
  displayTitle: PropTypes.bool,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
}

PhoneNumberField.defaultProps = {
  displayTitle: true,
  name: 'phoneNumber',
  formatFieldName: (name) => name,
  placeholder: 'Enter phone number',
}

export default PhoneNumberField
