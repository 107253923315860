import { useMutation } from '@apollo/react-hooks'
import { Button, Form } from 'antd'
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import VendorFormFields from 'components/AccessFormFields/VendorFormFields'
import Container from 'components/Container'
import Modal from 'components/Modal'
import Page from 'pages/Page'
import { track } from 'utils'
import { handleGraphQLErrors } from 'utils/error'

import * as UnlockForVendor from './graphql/mutations/unlockForVendor.graphql'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as UnlockPicture } from './unlockPicture.svg'
import styles from './VendorAccessPage.module.scss'

export default function VendorAccessPage(): JSX.Element {
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()
  const [errorModal, setErrorModal] = useState<{
    visible: boolean
    content: string | JSX.Element
  }>({ visible: false, content: '' })

  useEffect(() => {
    track.event(`access_page_vendor_view`)
  }, [])

  const checkCode = (code: string) => {
    const helpBlurb = (
      <>
        {' '}
        If you need assistance please text or call{' '}
        <a href="tel:+17372521486">(737) 252-1486</a>
      </>
    )

    switch (code) {
      case 'INVALID_WORK_ORDER':
        setErrorModal({
          visible: true,
          content: (
            <>
              The work order # you entered is not valid. Please try again.
              {helpBlurb}
            </>
          ),
        })
        break
      case 'EXPIRED_SCHEDULED_ACCESS':
        setErrorModal({
          visible: true,
          content: (
            <>
              Your scheduled access request has expired.
              {helpBlurb}
            </>
          ),
        })
        break
      case 'INVALID_LOCKBOX_SERIAL':
        setErrorModal({
          visible: true,
          content: (
            <>
              The lockbox serial # you entered is invalid. Please try again.
              {helpBlurb}
            </>
          ),
        })
        break
      case 'MAXIMUM_ACCESS_REQUESTS':
        setErrorModal({
          visible: true,
          content: (
            <>
              Unfortunately, you have already reached the maximum number of
              allowed entries for this home.
              {helpBlurb}
            </>
          ),
        })
        break
      case 'IGLOO_API_EXCEPTION':
        setErrorModal({
          visible: true,
          content:
            'This lockbox has been accessed too many times. Please try again later.',
        })
        break
      default:
        return false
    }
    return true
  }

  const [unlockForVendor, { loading }] = useMutation<UnlockForVendor.Variables>(
    UnlockForVendor.Mutation,
    {
      onError: (error) =>
        handleGraphQLErrors({
          ...error,
          form,
          defaultMessage: 'An error occurred. Please contact Darwin for help.',
          checkCode,
        }),
      onCompleted: () => {
        history.push(`${location.pathname}/success`)
      },
    }
  )

  return (
    <Page
      title="Darwin Homes | Vendor Access"
      description="Access"
      className={styles.page}
    >
      <Modal
        visible={errorModal.visible}
        footer={null}
        onCancel={() => setErrorModal({ visible: false, content: '' })}
        onClose={() => setErrorModal({ visible: false, content: '' })}
        maskStyle={{
          background: '#111e56',
          opacity: 0.9,
        }}
        width="300px"
        closable={false}
      >
        <div className={styles.modalContent}>
          {errorModal.content}
          <button
            className={styles.modalButton}
            onClick={() => setErrorModal({ visible: false, content: '' })}
          >
            Ok
          </button>
        </div>
      </Modal>
      <Container>
        <div className={styles.images}>
          <div className={styles.imagesBackground}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <UnlockPicture />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentBackground}>
            <div className={styles.welcomeHeader}>Welcome, Vendor!</div>
            <div className={styles.welcomeInstructions}>
              <p>
                When you arrive at the property, please locate the lockbox. Fill
                out the information below and we will text you a code to unlock
                the lockbox.
              </p>
              <p>
                If you have any questions, please call or text
                <a href="tel:+17372521486"> (737) 252-1486 </a>
                for assistance.
              </p>
            </div>
            <div>
              <Form
                form={form}
                onFinish={(values) => {
                  void unlockForVendor({
                    variables: {
                      input: {
                        ...values,
                      },
                    },
                  })
                }}
                scrollToFirstError
              >
                <VendorFormFields form={form} />
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className={styles.unlockButton}
                    type="primary"
                    loading={loading}
                    disabled={loading}
                  >
                    Unlock
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </Page>
  )
}
