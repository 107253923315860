import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'
import { useEffect } from 'react'

import ScheduleDayBricksField from 'components/Fields/ScheduleDayBricksField'
import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './ScheduleDayStep.module.scss'

interface ScheduleDayStepProps extends TourRequestStepProps {
  earliestSchedulableSelfTourDate?: Date | null
}

export default function ScheduleDayStep({
  setStep,
  stepName,
  earliestSchedulableSelfTourDate,
}: ScheduleDayStepProps): JSX.Element {
  const { values, setFieldValue } = useFormikContext<TourRequestForm>()

  useEffect(() => {
    if (
      dayjs(values.requestedTourDate).isBefore(
        dayjs(earliestSchedulableSelfTourDate)
      )
    ) {
      setFieldValue('requestedTourDate', earliestSchedulableSelfTourDate)
    }
  }, [])

  const handleClickNext = () => {
    const requestedTourDate = values.requestedTourDate
    if (requestedTourDate) {
      storage.setItem('requestedTourDate', requestedTourDate)
    }
    setStep('contact-info')
  }

  return (
    <Step
      stepLabel="Schedule a tour of the home"
      stepInfo="Select the day that works best for you"
      onClickNext={handleClickNext}
      onClickBack={() => setStep('felony')}
      stepName={stepName}
      fieldNames={['requestedTourDate']}
    >
      <ScheduleDayBricksField
        availableDate={earliestSchedulableSelfTourDate}
        requestedTourDate={values.requestedTourDate}
      />
      <div className={styles.tip}>
        <div className={styles.tipTitle}>Scheduling Tip</div>
        <div>
          For safety and security purposes, the individual completing the tour
          request must be present during the tour. A friend or family member
          cannot tour the home on your behalf.
        </div>
      </div>
    </Step>
  )
}
