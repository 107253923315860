/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AmenityCategory {
  APPLIANCE = "APPLIANCE",
  EXTERIOR = "EXTERIOR",
  INTERIOR = "INTERIOR",
  OTHER = "OTHER",
  PROPERTY = "PROPERTY",
}

/**
 * An enumeration.
 */
export enum TourRequestCategory {
  LEASING_AGENT = "LEASING_AGENT",
  MLS_AGENT = "MLS_AGENT",
  SELF = "SELF",
}

/**
 * An enumeration.
 */
export enum TourRequestPetCategories {
  BIRD = "BIRD",
  CAT = "CAT",
  DOG = "DOG",
  LIVESTOCK = "LIVESTOCK",
  OTHER = "OTHER",
  REPTILE = "REPTILE",
  RODENT = "RODENT",
}

/**
 * An enumeration.
 */
export enum TourRequestSelfReportedListingSource {
  CRAIGSLIST = "CRAIGSLIST",
  FACEBOOK = "FACEBOOK",
  HOTPADS = "HOTPADS",
  OTHER = "OTHER",
  TRULIA = "TRULIA",
  ZILLOW = "ZILLOW",
}

/**
 * An enumeration.
 */
export enum TourRequestSource {
  APARTMENTSCOM = "APARTMENTSCOM",
  DARWIN = "DARWIN",
  MLS = "MLS",
  ZILLOW = "ZILLOW",
  ZUMPER = "ZUMPER",
}

/**
 * An enumeration.
 */
export enum TourRequestStatus {
  PRE_QUALIFIED = "PRE_QUALIFIED",
  PRE_QUALIFIED_PENDING_IDENTITY_VERIFICATION = "PRE_QUALIFIED_PENDING_IDENTITY_VERIFICATION",
  PRE_QUALIFIED_PENDING_REVIEW = "PRE_QUALIFIED_PENDING_REVIEW",
  REVIEW = "REVIEW",
}

export interface CreatePublicTourFeedbackResponseInput {
  isHomeGoodCondition?: boolean | null;
  isHomeDirty?: boolean | null;
  isPropertyDamage?: boolean | null;
  isLockboxIssue?: boolean | null;
  isKeyMissing?: boolean | null;
  isUndesirableLocation?: boolean | null;
  isPriceTooHigh?: boolean | null;
  isNotPetFriendly?: boolean | null;
  isTourPositive?: boolean | null;
  isMissingAmenities?: boolean | null;
  hasChangedMind?: boolean | null;
  isUnhappyWithRentalRequirements?: boolean | null;
  isStillLooking?: boolean | null;
  other?: StringType | null;
  additionalComments?: StringType | null;
  tourRequestToken: StringType;
}

export interface CreateTourRequestInput {
  listingId: string;
  source: TourRequestSource;
  category?: TourRequestCategory | null;
  moveInStartDate: DateType;
  moveInEndDate: DateType;
  numPets: number;
  petCategories?: (TourRequestPetCategories | null)[] | null;
  annualIncomeAmount: number;
  hasCosigner?: boolean | null;
  creditScore: number;
  hasFelony?: boolean | null;
  requestedTourDate?: DateType | null;
  firstName: StringType;
  lastName: StringType;
  phoneNumber: StringType;
  email: EmailType;
  inquiryId: string;
  selfReportedListingSource?: TourRequestSelfReportedListingSource | null;
  selfReportedListingSourceOther?: StringType | null;
}

export interface ResendTourRequestSmsInput {
  publicToken: string;
}

export interface UnlockForSelfTourVerifiedLocationInput {
  lockboxSerialNumber: string;
  phoneNumber: StringType;
  isGeolocationEnabled: boolean;
  latitude?: number | null;
  longitude?: number | null;
}

export interface UnlockForVendorInput {
  lockboxSerialNumber: string;
  workOrderNumber: StringType;
  phoneNumber: StringType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
