import PropTypes from 'prop-types'

import BlockLoader from 'components/BlockLoader'

function PageLoader(props) {
  return <BlockLoader height="100%" text={props.text} />
}

PageLoader.propTypes = {
  text: PropTypes.string,
}

export default PageLoader
