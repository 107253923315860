import { Input } from 'antd'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import TextMask from 'react-text-mask'

function MaskedInput(props) {
  const { regex, value, mask, ...additionalProps } = props

  const convertMask = memoize((mask) => {
    if (typeof mask !== 'string') return mask

    const { regex } = props
    return [...mask].map((char) => regex[char] || char)
  })

  return (
    <TextMask
      mask={convertMask(mask)}
      render={(ref, additionalProps) => (
        <Input
          ref={(input) => ref(input && input.input)}
          {...additionalProps}
          value={value}
        />
      )}
      {...additionalProps}
    />
  )
}

MaskedInput.propTypes = {
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.func,
    PropTypes.bool,
    PropTypes.shape({
      mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
      pipe: PropTypes.func,
    }),
  ]).isRequired,
  guide: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pipe: PropTypes.func,
  placeholderChar: PropTypes.string,
  keepCharPositions: PropTypes.bool,
  showMask: PropTypes.bool,
  regex: PropTypes.object,
}

MaskedInput.defaultProps = {
  guide: false,
  regex: { 2: /[2-9]/, 9: /\d/, '*': /[*\d]/ },
}

export default MaskedInput
