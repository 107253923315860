import FormItem from '@darwin-homes/form-item'
import Input from '@darwin-homes/input'
import Radio, { RadioGroup } from '@darwin-homes/radio'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'

import { TourRequestSelfReportedListingSource } from '__generated__/globalTypes'
import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './ListingSourceStep.module.scss'

export default function ListingSourceStep({
  setStep,
  stepName,
}: TourRequestStepProps): JSX.Element {
  const { values } = useFormikContext<TourRequestForm>()

  const handleClickNext = () => {
    const selfReportedListingSource = values.selfReportedListingSource

    selfReportedListingSource &&
      storage.setItem('selfReportedListingSource', selfReportedListingSource)

    if (
      selfReportedListingSource === TourRequestSelfReportedListingSource.OTHER
    ) {
      storage.setItem(
        'selfReportedListingSourceOther',
        values.selfReportedListingSourceOther ?? ''
      )
    } else {
      storage.removeItem('selfReportedListingSourceOther')
    }
    setStep('move-in-date')
  }

  return (
    <Step
      stepLabel="How did you find this listing?"
      stepInfo="Tell us where you heard about our rental home"
      onClickNext={handleClickNext}
      stepName={stepName}
      fieldNames={
        values.selfReportedListingSource ===
        TourRequestSelfReportedListingSource.OTHER
          ? ['selfReportedListingSource', 'selfReportedListingSourceOther']
          : ['selfReportedListingSource']
      }
    >
      <FormItem name="selfReportedListingSource" className={styles.item}>
        <RadioGroup layout="vertical" name="selfReportedListingSource">
          <Radio
            label="Zillow"
            value={TourRequestSelfReportedListingSource.ZILLOW}
          />
          <Radio
            label="Trulia"
            value={TourRequestSelfReportedListingSource.TRULIA}
          />
          <Radio
            label="Hotpads"
            value={TourRequestSelfReportedListingSource.HOTPADS}
          />
          <Radio
            label="Craigslist"
            value={TourRequestSelfReportedListingSource.CRAIGSLIST}
          />
          <Radio
            label="Facebook"
            value={TourRequestSelfReportedListingSource.FACEBOOK}
          />
          <Radio
            label="Other"
            value={TourRequestSelfReportedListingSource.OTHER}
          />
        </RadioGroup>
      </FormItem>
      {values.selfReportedListingSource ===
        TourRequestSelfReportedListingSource.OTHER && (
        <FormItem
          name="selfReportedListingSourceOther"
          className={styles.item}
          required
        >
          <Input placeholder="Other Source" />
        </FormItem>
      )}
    </Step>
  )
}
