import { useMutation } from '@apollo/react-hooks'
import { Alert } from '@darwin-homes/alert'
import Button from '@darwin-homes/button'
import FormItem from '@darwin-homes/form-item'
import Input from '@darwin-homes/input/Input'
import { FormikProvider, useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { useGeolocated } from 'react-geolocated'
import { useHistory, useLocation } from 'react-router-dom'

import Container from 'components/Container'
import Nav from 'components/Nav'
import PageLoader from 'components/PageLoader'
import Page from 'pages/Page'
import { track } from 'utils'
import { handleGraphQLErrors } from 'utils/error'

import * as UnlockForSelfTourVerifiedLocation from './graphql/mutations/unlockForSelfTourVerifiedLocation.graphql'
import styles from './TourRequestAccessPage.module.scss'

interface UnlockFormValues {
  phoneNumber: string | null
  lockboxSerialNumber: string | null
}

export default function TourRequestAccessPage() {
  const history = useHistory()
  const location = useLocation()
  const [errorAlert, setErrorAlert] = useState<{
    type: string
    visible: boolean
    content: string | JSX.Element
  }>({ type: 'None', visible: false, content: '' })
  const [showInlineError, setShowInlineError] = useState(false)

  const initialValues: UnlockFormValues = {
    phoneNumber: null,
    lockboxSerialNumber: null,
  }

  useEffect(() => {
    track.event(`access_page_self_tour_verified_location_view`)
  }, [])

  const { coords, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })

  const checkCode = (code: string) => {
    const helpBlurb = (
      <>
        <br />
        If you need assistance please text or call{' '}
        <a href="tel:+17372521486">(737) 252-1486</a>
      </>
    )

    switch (code) {
      case 'EXPIRED_LISTING':
        setErrorAlert({
          type: 'EXPIRED_LISTING',
          visible: true,
          content: (
            <>
              This listing has expired. Check out our rentals page at{' '}
              <a href="/residents/rentals">darwinhomes.com/residents/rentals</a>{' '}
              for more available homes!
            </>
          ),
        })
        break
      case 'EXPIRED_SCHEDULED_ACCESS':
        setErrorAlert({
          type: 'EXPIRED_SCHEDULED_ACCESS',
          visible: true,
          content: (
            <>
              Your scheduled access request has expired.
              {helpBlurb}
            </>
          ),
        })
        break
      case 'INVALID_LOCKBOX_SERIAL':
        setErrorAlert({
          type: 'INVALID_LOCKBOX_SERIAL',
          visible: false,
          content: '',
        })
        setShowInlineError(true)
        void formik.setTouched({ lockboxSerialNumber: true })
        break
      case 'INVALID_LOCATION':
        setErrorAlert({
          type: 'INVALID_LOCATION',
          visible: true,
          content: (
            <>
              Please make sure you are at the home you are attempting to tour.
              {helpBlurb}
            </>
          ),
        })
        break
      case 'INCORRECT_PHONE_NUMBER':
        setErrorAlert({
          type: 'INCORRECT_PHONE_NUMBER',
          visible: false,
          content: '',
        })
        setShowInlineError(true)
        void formik.setTouched({ phoneNumber: true })
        break
      case 'MAXIMUM_ACCESS_REQUESTS':
        setErrorAlert({
          type: 'MAXIMUM_ACCESS_REQUESTS',
          visible: true,
          content: (
            <>
              Unfortunately, you have already reached the maximum number of
              allowed entries for this home.
              {helpBlurb}
            </>
          ),
        })
        break
      case 'IGLOO_API_EXCEPTION':
        setErrorAlert({
          type: 'IGLOO_API_EXCEPTION',
          visible: true,
          content:
            'This lockbox has been accessed too many times. Please try again later.',
        })
        break
      default:
        return false
    }
    return true
  }

  const [unlockForSelfTourVerifiedLocation, { loading }] =
    useMutation<UnlockForSelfTourVerifiedLocation.Variables>(
      UnlockForSelfTourVerifiedLocation.Mutation,
      {
        onError: (error) =>
          handleGraphQLErrors({
            ...error,
            form: null,
            defaultMessage:
              'An error occurred. Please contact leasing@darwinhomes.com for help.',
            checkCode,
          }),
        onCompleted: () => history.push(`${location.pathname}/success`),
      }
    )

  const handleSubmit = async () => {
    const { phoneNumber, lockboxSerialNumber } = formik.values
    const { latitude, longitude } = coords || {}

    await unlockForSelfTourVerifiedLocation({
      variables: {
        input: {
          phoneNumber,
          lockboxSerialNumber,
          latitude,
          longitude,
          isGeolocationEnabled,
        },
      },
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  return (
    <Page
      title="Darwin Homes | Access"
      description="Access"
      className={styles.page}
    >
      {loading && <PageLoader />}
      <Nav />
      <Container>
        {errorAlert.visible && (
          <Alert
            onClose={() =>
              setErrorAlert({ type: '', visible: false, content: '' })
            }
            message={errorAlert.content}
            type="error"
            className={styles.alert}
          />
        )}
        <div className={styles.containerWrapper}>
          <div className={styles.stepContainer}>
            <h1 className={errorAlert.visible ? styles.hasAlert : ''}>
              Welcome to your tour
            </h1>
            <h2>
              To gain access to the property, please locate the lockbox and fill
              out the information below to start the unlocking process
            </h2>
            <FormikProvider value={formik}>
              <FormItem
                name="lockboxSerialNumber"
                className={styles.item}
                validate={() => {
                  if (
                    errorAlert.type === 'INVALID_LOCKBOX_SERIAL' &&
                    showInlineError
                  ) {
                    return 'The serial number you submitted is not valid. This number is either a 6 or 10 digit code located on the front of the lockbox. Please include the 0s'
                  }
                }}
                onChange={() => setShowInlineError(false)}
                required
                requiredMessage="This number is either a 6 or 10 digit code located on the front of the lockbox. Please include the 0s"
              >
                <Input
                  placeholder="Lockbox Serial Number"
                  helperText="This number is either a 6 or 10 digit code located on the front of the lockbox. Please include the 0s"
                />
              </FormItem>
              <FormItem
                name="phoneNumber"
                className={styles.item}
                validate={() => {
                  if (
                    errorAlert.type === 'INCORRECT_PHONE_NUMBER' &&
                    showInlineError
                  ) {
                    return 'Please enter the correct phone number'
                  }
                }}
                onChange={() => setShowInlineError(false)}
                required
              >
                <Input type="tel" placeholder="Phone Number" />
              </FormItem>
              <div className={styles.tip}>
                <div className={styles.tipTitle}>Access Reminders</div>
                <ul className={styles.tipList}>
                  <li>
                    For safety and security purposes, the individual that
                    completed the tour request must be present during the tour.
                    A friend or family member cannot tour the home on your
                    behalf.
                  </li>
                  <li>
                    Please remember to place keys back into the lockbox after
                    you are done with your tour.
                  </li>
                </ul>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  variant="primary"
                  className={styles.btn}
                  onClick={formik.submitForm}
                  disabled={loading}
                >
                  Unlock
                </Button>
              </div>
            </FormikProvider>
          </div>
        </div>
      </Container>
    </Page>
  )
}
