import safeAreaInsets from 'safe-area-insets'

export { default as track } from './track'

export function isMobile() {
  return actualWidth() < 800
}

export function actualWidth() {
  return window.innerWidth - safeAreaInsets.left - safeAreaInsets.right
}

export function baseUrl() {
  const loc = window.location
  return loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port : '')
}
