import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/features/array/includes'
import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import { EventHint } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ApolloError } from 'apollo-boost'
import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand'
import 'hamburgers/dist/hamburgers.min.css'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { Provider } from 'unstated'
import './global.less' // This needs to go before the App import or else our styles sometimes don't override the antd defaults

import App from 'components/App'
import ScrollToTop from 'components/App/ScrollToTop'
import { darwinClient } from 'graphql/clients'

const env = dotenv.config()
dotenvExpand(env)

smoothscroll.polyfill()

function getApolloErrorFromHint({
  originalException,
}: EventHint): ApolloError | undefined {
  if (originalException instanceof ApolloError) {
    return originalException
  }
}

function isGraphQLError({ graphQLErrors }: ApolloError): boolean {
  return Boolean(graphQLErrors?.length)
}

function isNetworkError({ networkError }: ApolloError): boolean {
  return Boolean(networkError)
}

if (['production', 'staging'].includes(process.env.REACT_APP_NODE_ENV)) {
  // The build completes before heroku updates the version number.
  // Release Version comes in as 'v{number}'
  // Increment by 1 to keep in step with heroku.
  const previousReleaseVersion = process.env.REACT_APP_HEROKU_RELEASE_VERSION
  const releaseVersion = parseInt(previousReleaseVersion.slice(1), 10) + 1
  const release = `${process.env.REACT_APP_HEROKU_APP_NAME}@v${releaseVersion}`
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_NODE_ENV,
    release,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
    sampleRate: 1,
    ignoreErrors: [
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/24
      'Object Not Found Matching Id',
      'Failed to fetch',
      'ChunkLoadError',
    ],
    beforeSend(event, hint) {
      const error = getApolloErrorFromHint(hint)
      if (error && (isGraphQLError(error) || isNetworkError(error))) {
        return null
      }
      return event
    },
  })
}

ReactDOM.render(
  <Provider>
    <ApolloProvider client={darwinClient}>
      <Router>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
)
