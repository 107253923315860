import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import styles from './FeedbackButton.module.scss'

interface FeedbackButtonProps {
  type: 'good' | 'bad'
  handleClick: () => unknown
  isTourPositive: boolean | undefined
}

export default function FeedbackButton({
  type,
  handleClick,
  isTourPositive,
}: PropsWithChildren<FeedbackButtonProps>): JSX.Element {
  const selectedGood = isTourPositive == true ? styles.setGood : styles.good
  const selectedBad = isTourPositive == false ? styles.setBad : styles.bad

  return (
    <div
      className={classNames(
        styles.button,
        type == 'good' ? selectedGood : selectedBad
      )}
      onClick={handleClick}
    >
      {type == 'good' ? <ThumbUpIcon /> : <ThumbDownIcon />}
    </div>
  )
}
