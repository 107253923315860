import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Container from 'components/Container'
import Nav from 'components/Nav'
import ResendTextButton from 'components/ResendTextButton'
import Page from 'pages/Page'
import { Response } from 'pages/TourRequestWizardPage/graphql/mutations/createTourRequest.graphql'
import { track } from 'utils'
import { formatMoney } from 'utils/money'

import styles from './TourRequestFinishedPage.module.scss'

export default function TourRequestFinishedPage() {
  const { state } = useLocation<Response>()
  const { tourRequest } = state?.createTourRequest || {}

  if (!tourRequest) window.location.replace('/residents/rentals')

  const { listing, source } = tourRequest || {}
  const { id: listingId, unit, rentAmount, availableDate } = listing || {}
  const {
    id: unitId,
    fullAddress,
    market,
    isUnitAvailable,
    petsAllowed,
    numBathrooms,
    numBedrooms,
    sqFootage,
  } = unit || {}
  const marketName = market?.name

  useEffect(() => {
    track.event('tour_request_confirmation_page_view', {
      listingId,
      unitId,
      fullAddress,
      market: marketName,
      tourRequestSource: source,
    })
  }, [listingId, unitId, fullAddress, marketName, source])

  const unavailableTour = () => {
    if (availableDate)
      return (
        <div>
          The home will be ready for tour on{' '}
          <b>{dayjs(availableDate).format('dddd, MMMM D, YYYY')}</b>. We will
          let you know when the home is ready for you to tour.
        </div>
      )

    return (
      <div>
        The home is not ready to tour yet. We will let you know when it is
        ready.
      </div>
    )
  }

  return (
    <Page
      id="tourrequest"
      title="Darwin Homes | Tour Request Success"
      description="Darwin Homes Tour Request Success Page"
    >
      <Nav marketName={marketName} />
      {tourRequest && (
        <Container className={styles.container}>
          <div className={styles.content}>
            <h1>
              {isUnitAvailable
                ? 'Tour request submitted!'
                : "You're on the waitlist!"}
            </h1>
            {isUnitAvailable ? (
              <div>
                We will reach out to you shortly via text message with next
                steps and instructions
              </div>
            ) : (
              unavailableTour()
            )}
            <div className={styles.listingInfo}>
              <div>{fullAddress}</div>
              <div className={styles.listingItems}>
                <div>{petsAllowed ? 'Pets Allowed' : 'Pets Not Allowed'}</div>
                <div className={styles.square} />
                <div>{numBedrooms} beds</div>
                <div className={styles.square} />
                <div>{numBathrooms} baths</div>
                <div className={styles.square} />
                <div>{sqFootage} sqft</div>
              </div>
              <div>{formatMoney(rentAmount)}</div>
            </div>
          </div>
          <div className={styles.tip}>
            <div className={styles.tipTitle}>What to Expect</div>
            <div className={styles.tipSubTitle}>Access</div>
            <ul className={styles.tipList}>
              <li>
                If your tour request is accepted, you will receive instructions
                to access the home for a tour the day of the scheduled
                appointment.
              </li>
              <li>
                You must be present at the the allotted date/time of the tour. A
                friend or family member cannot tour the home on your behalf.
              </li>
            </ul>
            <div className={styles.tipSubTitle}>Application</div>
            <ul className={styles.tipList}>
              <li>
                If you would like to apply for the home after the tour, we only
                accept applications submitted from{' '}
                <a href="/residents/rentals">
                  www.darwinhomes.com/residents/rentals
                </a>
              </li>
              <li>
                Do not wire money or pay anyone with gift cards. Darwin will
                never ask you to pay outside of our resident portal.
              </li>
            </ul>
          </div>
          {isUnitAvailable && <ResendTextButton tourRequest={tourRequest} />}
        </Container>
      )}
    </Page>
  )
}
