import device from 'current-device'
import storage from 'local-storage-fallback'

import { DAY } from 'constants/time'

import { isMobile } from './index'

const GCLID = 'gclid'
const GCLID_TIMESTAMP = 'gclidTimestamp'
const GCLID_EXPIRATION_TIMEOUT = 30 * DAY

const track = {
  page: function (name, params = {}) {
    window.analytics &&
      window.analytics.page(name, {
        platform: device.desktop() ? 'desktop' : 'mobile',
        responsive: isMobile(),
        ...params,
      })
  },

  event: function (name, params = {}) {
    window.analytics && window.analytics.track(name, params)
  },

  setGoogleClickId: (gclid) => {
    if (!track.getGoogleClickId()) {
      storage.setItem(GCLID, gclid)
      storage.setItem(GCLID_TIMESTAMP, Date.now())
    }
  },

  getGoogleClickId: () => {
    let gclid = storage.getItem(GCLID)
    if (gclid) {
      const timestamp = storage.getItem(GCLID_TIMESTAMP)
      if (timestamp) {
        if (parseInt(timestamp, 10) + GCLID_EXPIRATION_TIMEOUT < Date.now()) {
          storage.removeItem(GCLID)
          storage.removeItem(GCLID_TIMESTAMP)
          gclid = null
        }
      } else {
        storage.setItem(GCLID_TIMESTAMP, Date.now())
      }
    }

    return gclid
  },
}

export default track
