import FormItem from '@darwin-homes/form-item'
import Radio, { RadioGroup } from '@darwin-homes/radio'
import classnames from 'classnames'
import dayjs from 'dayjs'
import range from 'lodash/range'

import styles from './ScheduleDayBricksField.module.scss'

interface ScheduleDayBricksFieldProps {
  availableDate?: Date | null
  requestedTourDate?: string | null
}

export default function ScheduleDayField({
  availableDate,
  requestedTourDate,
}: ScheduleDayBricksFieldProps): JSX.Element {
  const days = range(8).map((i) => dayjs(availableDate).add(i, 'days'))

  return (
    <FormItem name="requestedTourDate" className={styles.item}>
      <RadioGroup name="requestedTourDate" layout="vertical">
        {days.map((day) => {
          const formattedDay = day.format('YYYY-MM-DD')
          return (
            <Radio
              value={formattedDay}
              key={formattedDay}
              label={day.format('dddd, MMMM D, YYYY')}
              wrapperClass={classnames(styles.radioWrapper, {
                [styles.selected]:
                  requestedTourDate && requestedTourDate === formattedDay,
              })}
              className={styles.radio}
            />
          )
        })}
      </RadioGroup>
    </FormItem>
  )
}
