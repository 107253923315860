import classNames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import MenuLink from 'components/Menu/MenuLink'

import chevronLeft from './chevron-left.svg'
import chevronRight from './chevron-right.svg'
import styles from './styles.module.scss'

class Item extends PureComponent {
  render() {
    const {
      name,
      link,
      trackingId,
      comingSoon,
      indicator,
      onClick,
      onExternalLink,
      selected,
      className,
      isToNewLanding,
    } = this.props
    const clazz = {
      [styles.item]: true,
      [styles.comingSoon]: comingSoon,
      [styles.selected]: selected,
    }

    if (className) {
      clazz[className] = true
    }

    return (
      <div onClick={onClick} className={classNames(clazz)}>
        {link ? (
          <MenuLink
            path={link}
            title={name}
            trackingId={trackingId}
            isToNewLanding={isToNewLanding}
            theme={styles}
            onExternalLink={onExternalLink}
          />
        ) : (
          <>
            {indicator === 'left' && (
              <img src={chevronLeft} alt="" className={styles.chevronLeft} />
            )}
            <span className={styles.link}>{name}</span>
            {comingSoon && (
              <span className={styles.comingSoonText}>Coming Soon</span>
            )}
            {indicator === 'right' && (
              <img src={chevronRight} alt="" className={styles.chevronRight} />
            )}
          </>
        )}
      </div>
    )
  }
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  trackingId: PropTypes.string,
  comingSoon: PropTypes.bool,
  indicator: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  onExternalLink: PropTypes.func,
  selected: PropTypes.bool,
  isToNewLanding: PropTypes.bool,
}

export default Item
