import { ArrowRightIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import Logo from 'components/Logo'
import { track } from 'utils'

import styles from './Nav.module.scss'

interface NavProps {
  marketName?: string
}

export default function Nav({ marketName }: NavProps): JSX.Element {
  const { pathname } = useLocation()
  const isTourRequest = pathname.includes('tour-request')
  const isTourRequestFinished = pathname.includes('finished')
  const isAccess = pathname.includes('access')

  return (
    <div className={classNames(styles.navWrapper, styles.sticky)}>
      <nav className={styles.nav}>
        <div className={styles.dropDownContainer}>
          <div>
            <a href="/" className={styles.logo}>
              <Logo />
            </a>
          </div>
        </div>
        <div className={styles.navDesktop}>
          {isTourRequest && !isTourRequestFinished && (
            <div className={styles.navItem}>Tour Request</div>
          )}
          {isTourRequestFinished && (
            <div className={styles.navLink}>
              <a
                href={`/residents/rentals${
                  marketName ? `?market=${marketName}` : ''
                }`}
                onClick={() => {
                  track.event(
                    `tour_request_confirmation_page_see_other_${marketName}_homes_click`
                  )
                }}
              >
                See More Homes
                <ArrowRightIcon className={styles.icon} />
              </a>
            </div>
          )}
          {isAccess && (
            <div className={styles.navLink}>
              Need Help?
              <a href="tel:8333279461">(833)327-9461</a>
            </div>
          )}
        </div>
      </nav>
    </div>
  )
}
