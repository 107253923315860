import { notification } from 'antd'

import { ErrorCodes } from 'constants/errors'
import { displayFormErrors } from 'utils/form'

export function handleGraphQLErrors({
  form,
  graphQLErrors,
  defaultMessage = 'There seems to be a problem',
  checkCode,
}) {
  if (!graphQLErrors) {
    return
  }
  const { normalized } = graphQLErrors
  if (!normalized) {
    return
  }

  const { code, info } = normalized

  if (
    form &&
    code === ErrorCodes.INVALID_INPUT &&
    info &&
    !(info instanceof Array)
  ) {
    const { getFieldInstance } = form
    let field
    for (field in info) {
      if (
        Object.prototype.hasOwnProperty.call(info, field) &&
        getFieldInstance(field)
      ) {
        displayFormErrors({ form, formErrors: normalized })
        return
      }
    }
  }

  if (checkCode && checkCode(code)) {
    return
  }

  notifyError({ graphQLErrors }, { defaultMessage })
}

export function normalizeError(error) {
  let errorCode = null
  let errorMessage = ''
  let errorDescription = ''
  let additionalErrors = null
  let info = null

  if (error) {
    const { graphQLErrors, networkError, message } = error
    if (networkError) {
      errorMessage = 'There seems to be a problem'
      errorDescription = networkError.message
    } else if (graphQLErrors && graphQLErrors.length) {
      const firstError = graphQLErrors[0]
      const { message, extensions } = firstError
      if (message) {
        errorMessage = message
      }
      if (extensions) {
        const { code, exception } = extensions
        if (code) {
          errorCode = code
          errorDescription = `Error code: ${code}`
          if (code === ErrorCodes.INVALID_INPUT) {
            const { validationErrors } = exception
            if (validationErrors instanceof Array && validationErrors.length) {
              errorMessage = validationErrors[0]
            }
            info = validationErrors
          }
        }
      }
      if (graphQLErrors.length > 1) {
        additionalErrors = graphQLErrors.slice(1).map((err) => err.message)
      }
    } else {
      errorMessage = message
    }
  }

  return {
    code: errorCode,
    message: errorMessage,
    description: errorDescription,
    additionalErrors,
    info,
    duration: 0,
  }
}

export function notifyError(
  { networkError, graphQLErrors },
  { defaultMessage } = {}
) {
  const { normalized } = networkError || graphQLErrors || {}
  if (!normalized) {
    return
  }
  const { message, description } = normalized
  if (!description && defaultMessage) {
    normalized.message = defaultMessage
    normalized.description = message
  }
  notification.error(normalized)
}
