import { notifyError, normalizeError } from 'utils/error'

export const onError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const { message, locations, path, extensions } = err
      const { code } = extensions || {}
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${code}`
      )
    }

    graphQLErrors.normalized = normalizeError({ graphQLErrors })
  }
  if (networkError && !networkError.graphQLErrors) {
    console.log(`[Network error]: ${networkError}`)
    const normalized = normalizeError({ networkError })
    networkError.normalized = normalized
    notifyError({ networkError })
  }
}
