import { useRef, useEffect } from 'react'

/*
 * Return the previous value of a variable (typically a prop or state variable)
 * Inspiration:
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export default function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
