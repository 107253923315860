import { Modal as AntModal } from 'antd'
import { useLayoutEffect } from 'react'

const DW_MODAL_VISIBLE_CLASS = 'dw-modal-visible'

const addModalVisibleClass = () =>
  document.body.classList.add(DW_MODAL_VISIBLE_CLASS)
const removeModalVisibleClass = () =>
  document.body.classList.remove(DW_MODAL_VISIBLE_CLASS)

function Modal(props) {
  const { visible } = props

  useLayoutEffect(() => {
    if (visible) {
      addModalVisibleClass()
    } else {
      removeModalVisibleClass()
    }

    return removeModalVisibleClass
  }, [visible])

  return <AntModal {...props} />
}

export default Modal
