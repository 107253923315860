import Logo from 'assets/darwin-logo-white.svg'
import equalHousing from 'assets/equal-housing.png'
import { track } from 'utils'

import styles from './styles.module.css'

function FooterFloor() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.legal}>
          <a href="/">
            <img alt="Darwin Homes Logo" className="logo" src={Logo} />
          </a>
          <p>
            © 2021 Darwin Homes, Inc. All rights Reserved.{' '}
            <a href="/privacy">Privacy Policy</a> &bull;{' '}
            <a href="/terms">Terms and Conditions</a>
          </p>
          <p>
            <a
              href="/files/CN%201-2.pdf"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackProtectionNotice}
            >
              Texas Real Estate Commission Consumer Protection Notice
            </a>{' '}
            •{' '}
            <a
              href="https://res.cloudinary.com/darwin-homes/image/upload/v1633377479/Public/TREC_IABS_2021_dcqubu.pdf"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackInformation}
            >
              Texas Real Estate Commission Information About Brokerage Services
            </a>
          </p>
        </div>
        <p className={styles.help}>
          Need help? Let us know!{' '}
          <a href="mailto:partnerships@darwinhomes.com" onClick={trackHelp}>
            partnerships@darwinhomes.com
          </a>
        </p>
      </div>
      <img
        src={equalHousing}
        alt="Equal Housing Opportunity"
        className={styles.equalHousing}
      />
    </>
  )
}

function trackHelp() {
  track.event('clicked_help_email')
}

function trackProtectionNotice() {
  track.event('clicked_tx_consumer_protection_notice')
}

function trackInformation() {
  track.event('clicked_tx_about_brokerage_services')
}

export default FooterFloor
