import gql from 'graphql-tag'

import {
  getListingBanner,
  getListingBannerVariables,
} from './__generated__/getListingBanner'

export type Response = getListingBanner

export type Variables = getListingBannerVariables

export const Query = gql`
  query getListingBanner($id: ID!) {
    publicListing(id: $id) {
      id
      previewImageUrl
      unit {
        id
        numBedrooms
        numBathrooms
        sqFootage
        catsAllowed
        petsAllowed
        smallDogsAllowed
        largeDogsAllowed
        maxPetsAllowed
        fullAddress
        shortAddress
        depositAmount
        minLeaseTermMonths
        maxLeaseTermMonths
        amenities {
          id
          name
          category
        }
        activeListing {
          id
          availableDate
          rentAmount
          applicationFeeAmount
        }
        market {
          id
          name
        }
      }
    }
  }
`
