import { useQuery, useMutation } from '@apollo/react-hooks'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { Progress } from 'antd'
import dayjs from 'dayjs'
import { useFormik, FormikProvider } from 'formik'
import storage from 'local-storage-fallback'
import moment from 'moment'
import { useEffect } from 'react'
import { Wizard, Steps, Step } from 'react-albus'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import {
  TourRequestPetCategories,
  TourRequestSelfReportedListingSource,
} from '__generated__/globalTypes'
import BathroomIcon from 'assets/Icon/BathroomIcon'
import BedroomIcon from 'assets/Icon/BedroomIcon'
import SqFeetIcon from 'assets/Icon/SqFeetIcon'
import Container from 'components/Container'
import ErrorPanel from 'components/ErrorPanel'
import Nav from 'components/Nav'
import PageLoader from 'components/PageLoader'
import Page from 'pages/Page'
import { track } from 'utils'
import { handleGraphQLErrors } from 'utils/error'
import { formatMoney } from 'utils/money'

import ContactInfoStep from './ContactInfoStep'
import CreditScoreStep from './CreditScoreStep'
import FelonyStep from './FelonyStep'
import * as CreateTourRequest from './graphql/mutations/createTourRequest.graphql'
import * as GetPublicUnit from './graphql/queries/getPublicUnit.graphql'
import IncomeStep from './IncomeStep'
import ListingSourceStep from './ListingSourceStep'
import MoveInDateStep from './MoveInDateStep/MoveInDateStep'
import NumberOfPetsStep from './NumberOfPetsStep/NumberOfPetsStep'
import PersonaVerificationStep from './PersonaVerificationStep'
import PetCategoryStep from './PetCategoryStep'
import ScheduleDayStep from './ScheduleDayStep'
import styles from './TourRequestWizardPage.module.scss'

export interface TourRequestForm {
  listingId: string
  moveInStartDate: string
  moveInEndDate: string
  numPets: string
  hasPets: boolean | null
  hasDog: boolean
  hasCat: boolean
  hasBird: boolean
  hasLivestock: boolean
  hasReptile: boolean
  hasRodent: boolean
  hasOther: boolean
  annualIncomeAmount: number
  hasCosigner: boolean
  creditScore: string
  hasFelony: boolean
  requestedTourDate: string | null
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  email: string | null
  source: string | null
  inquiryId: string | null
  selfReportedListingSource: string | null
  selfReportedListingSourceOther: string | null
}

export interface TourRequestStepProps {
  setStep: (step: string) => void
  stepName: string
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
const tourRequestSchema = Yup.object().shape({
  moveInEndDate: Yup.string()
    .required('The move in end date is required')
    .nullable(),
  numPets: Yup.number()
    .min(0, "The number of pets can't be negative")
    .required('Please input the number of pets you have'),
  annualIncomeAmount: Yup.number().required(
    'Please input your household income'
  ),
  creditScore: Yup.number()
    .min(250, '250 is the lowest possible credit score')
    .max(900, '900 is the highest possible credit score')
    .required('Please input your credit score'),
  hasPets: Yup.bool().when(
    [
      'numPets',
      'hasDog',
      'hasCat',
      'hasBird',
      'hasLivestock',
      'hasReptile',
      'hasRodent',
      'hasOther',
    ],
    {
      is: (
        numPets: number,
        hasDog: boolean,
        hasCat: boolean,
        hasBird: boolean,
        hasLivestock: boolean,
        hasReptile: boolean,
        hasRodent: boolean,
        hasOther: boolean
      ) =>
        numPets == 0 ||
        hasDog ||
        hasCat ||
        hasBird ||
        hasLivestock ||
        hasReptile ||
        hasRodent ||
        hasOther,
      then: Yup.bool().notRequired().nullable(),
      otherwise: Yup.bool()
        .required('Please select at least one pet category')
        .nullable(),
    }
  ),
  requestedTourDate: Yup.string()
    .required('Please choose a day to request a tour')
    .nullable(),
  firstName: Yup.string().required('Please enter your first name').nullable(),
  lastName: Yup.string().required('Please enter your last name').nullable(),
  email: Yup.string()
    .required('Please enter your email address')
    .matches(emailReg, 'The email you entered is not valid')
    .nullable(),
  phoneNumber: Yup.string()
    .required('Please enter your phone number')
    .matches(phoneRegExp, 'The phone number you entered is not valid')
    .nullable(),
  selfReportedListingSourceOther: Yup.string().when(
    'selfReportedListingSource',
    {
      is: (selfReportedListingSource: string) =>
        selfReportedListingSource ===
        TourRequestSelfReportedListingSource.OTHER,
      then: Yup.string()
        .required('Please enter how you found this listing')
        .nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }
  ),
})

const STEPS = [
  'listing-source',
  'move-in-date',
  'number-of-pets',
  'pet-categories',
  'income',
  'credit-score',
  'felony',
  'schedule-tour',
  'contact-info',
  'identification-document',
]

export default function TourRequestWizardPage() {
  const { slug, step } = useParams<any>()
  const history = useHistory()
  const { search } = useLocation()

  const tourRequestSource = storage.getItem('tourRequestSource')

  const { loading, data, error } = useQuery<
    GetPublicUnit.Response,
    GetPublicUnit.Variables
  >(GetPublicUnit.Query, {
    variables: { slug },
    skip: !slug,
    onCompleted: (data) => {
      const { slug, activeListing } = data.publicUnit || {}

      if (!activeListing) {
        window.location.assign(`/residents/units/${slug}`)
        return
      }

      const { id } = activeListing

      const storedStep = storage.getItem(`listing${id}Step`) || STEPS[0]

      let newStep = step

      if (STEPS.indexOf(step) > STEPS.indexOf(storedStep)) {
        newStep = storedStep
      }

      if (newStep !== step) {
        history.push(newStep)
      }
    },
  })

  const unit = data?.publicUnit

  const {
    id: unitId,
    petsAllowed,
    fullAddress,
    numBedrooms,
    numBathrooms,
    sqFootage,
    market,
    activeListing,
    isUnitAvailable,
  } = unit || {}

  const {
    id: listingId,
    rentAmount,
    isSelfShowing,
    earliestSchedulableSelfTourDate,
  } = activeListing || {}

  const setStep = (step: string) => {
    history.push(`${step}${search}`)
    storage.setItem(`listing${listingId}Step`, step)
    window.scrollTo(0, 0)
  }

  const [createTourRequest, { loading: loadingCreateTourRequest }] =
    useMutation<CreateTourRequest.Response>(CreateTourRequest.Mutation, {
      onError: (error) =>
        handleGraphQLErrors({
          ...error,
          form: undefined,
          defaultMessage:
            'An error occurred. Please contact leasing@darwinhomes.com for help.',
          checkCode: false,
        }),
      onCompleted: (data) => {
        storage.removeItem('numPets')
        storage.removeItem('hasDog')
        storage.removeItem('hasCat')
        storage.removeItem('hasBird')
        storage.removeItem('hasLivestock')
        storage.removeItem('hasReptile')
        storage.removeItem('hasRodent')
        storage.removeItem('hasOther')
        storage.removeItem('moveInStartDate')
        storage.removeItem('moveInEndDate')
        storage.removeItem('annualIncomeAmount')
        storage.removeItem('hasCosigner')
        storage.removeItem('creditScore')
        storage.removeItem('hasFelony')
        storage.removeItem('requestedTourDate')
        storage.removeItem('firstName')
        storage.removeItem('lastName')
        storage.removeItem('email')
        storage.removeItem('phoneNumber')
        storage.removeItem('tourRequestSource')
        storage.removeItem(`listing${listingId}Step`)
        storage.removeItem('selfReportedListingSource')
        storage.removeItem('selfReportedListingSourceOther')

        history.push('/tour-request/finished/', data)
      },
    })

  const stepEventName = step.replace('-', '_')
  const pageViewEventName = `tour_request_${stepEventName}_page_view`

  useEffect(() => {
    track.event(pageViewEventName, {
      listingId,
      unitId,
      fullAddress,
      market: market?.name,
      tourRequestSource,
    })
  }, [
    pageViewEventName,
    listingId,
    unitId,
    fullAddress,
    market,
    tourRequestSource,
  ])

  const today = new Date()

  const initialValues: TourRequestForm = {
    listingId: listingId || '',
    moveInStartDate:
      storage.getItem('moveInStartDate') || dayjs(today).format('YYYY-MM-DD'),
    moveInEndDate:
      storage.getItem('moveInEndDate') ||
      dayjs(today).add(10, 'day').format('YYYY-MM-DD'),
    numPets: storage.getItem('numPets') || '0',
    hasPets: null,
    hasDog: JSON.parse(storage.getItem('hasDog') || 'false'),
    hasCat: JSON.parse(storage.getItem('hasCat') || 'false'),
    hasBird: JSON.parse(storage.getItem('hasBird') || 'false'),
    hasLivestock: JSON.parse(storage.getItem('hasLivestock') || 'false'),
    hasReptile: JSON.parse(storage.getItem('hasReptile') || 'false'),
    hasRodent: JSON.parse(storage.getItem('hasRodent') || 'false'),
    hasOther: JSON.parse(storage.getItem('hasOther') || 'false'),
    annualIncomeAmount: parseInt(
      storage.getItem('annualIncomeAmount') || '4000000'
    ),
    hasCosigner: JSON.parse(storage.getItem('hasCosigner') || 'false'),
    creditScore: storage.getItem('creditScore') || '700',
    hasFelony: JSON.parse(storage.getItem('hasFelony') || 'false'),
    requestedTourDate:
      storage.getItem('requestedTourDate') || dayjs(today).format('YYYY-MM-DD'),
    selfReportedListingSource:
      storage.getItem('selfReportedListingSource') ||
      TourRequestSelfReportedListingSource.ZILLOW,
    selfReportedListingSourceOther: storage.getItem(
      'selfReportedListingSourceOther'
    ),
    firstName: storage.getItem('firstName'),
    lastName: storage.getItem('lastName'),
    phoneNumber: storage.getItem('phoneNumber'),
    email: storage.getItem('email'),
    source: tourRequestSource,
    inquiryId: null,
  }

  const index = STEPS.indexOf(step)

  const hidePage = loading || loadingCreateTourRequest || error

  const handleSubmit = () => {
    // Have to use storage because form fields in steps don't load on page refresh
    const inquiryId = storage.getItem('inquiryId')

    const numPets = storage.getItem('numPets')

    const petCategories = []
    if (numPets && parseInt(numPets) > 0) {
      if (storage.getItem('hasDog') && storage.getItem('hasDog') === 'true') {
        petCategories.push(TourRequestPetCategories.DOG)
      }
      if (storage.getItem('hasCat') && storage.getItem('hasCat') === 'true') {
        petCategories.push(TourRequestPetCategories.CAT)
      }
      if (storage.getItem('hasBird') && storage.getItem('hasBird') === 'true') {
        petCategories.push(TourRequestPetCategories.BIRD)
      }
      if (
        storage.getItem('hasLivestock') &&
        storage.getItem('hasLivestock') === 'true'
      ) {
        petCategories.push(TourRequestPetCategories.LIVESTOCK)
      }
      if (
        storage.getItem('hasReptile') &&
        storage.getItem('hasReptile') === 'true'
      ) {
        petCategories.push(TourRequestPetCategories.REPTILE)
      }
      if (
        storage.getItem('hasRodent') &&
        storage.getItem('hasRodent') === 'true'
      ) {
        petCategories.push(TourRequestPetCategories.RODENT)
      }
      if (
        storage.getItem('hasOther') &&
        storage.getItem('hasOther') === 'true'
      ) {
        petCategories.push(TourRequestPetCategories.OTHER)
      }
    }

    const selfReportedListingSource = storage.getItem(
      'selfReportedListingSource'
    )
    if (!selfReportedListingSource) {
      setStep('listing-source')
      return
    }

    const selfReportedListingSourceOther = storage.getItem(
      'selfReportedListingSourceOther'
    )

    const moveInEndDate = storage.getItem('moveInEndDate')
    const moveInStartDate = storage.getItem('moveInStartDate')
    if (!(moveInEndDate && moveInEndDate)) {
      setStep('move-in-date')
      return
    }

    if (numPets === null) {
      setStep('number-of-pets')
      return
    }
    if (numPets && parseInt(numPets) > 0 && !petCategories.length) {
      setStep('pet-categories')
      return
    }

    const annualIncomeAmount = storage.getItem('annualIncomeAmount')
    if (!annualIncomeAmount) {
      setStep('income')
      return
    }

    const creditScore = storage.getItem('creditScore')
    if (!creditScore) {
      setStep('credit-score')
      return
    }

    const hasFelony = storage.getItem('hasFelony')
    if (!hasFelony) {
      setStep('felony')
      return
    }

    let requestedTourDate
    if (isUnitAvailable) {
      requestedTourDate = storage.getItem('requestedTourDate')
      if (!requestedTourDate) {
        setStep('schedule-tour')
        return
      }
    }

    const firstName = storage.getItem('firstName')
    const lastName = storage.getItem('lastName')
    const email = storage.getItem('email')
    const phoneNumber = storage.getItem('phoneNumber')
    if (!firstName || !lastName || !email || !phoneNumber) {
      setStep('contact-info')
      return
    }

    const hasCosigner = storage.getItem('hasCosigner') || false

    void createTourRequest({
      variables: {
        input: {
          listingId,
          moveInStartDate: moment(moveInStartDate).format('YYYY-MM-DD'),
          moveInEndDate: moment(moveInEndDate).format('YYYY-MM-DD'),
          numPets,
          petCategories,
          annualIncomeAmount: Math.min(
            parseInt(annualIncomeAmount),
            10_000_000_00
          ),
          hasCosigner: hasCosigner ? JSON.parse(hasCosigner) : hasCosigner,
          creditScore,
          hasFelony: JSON.parse(hasFelony),
          requestedTourDate: requestedTourDate,
          firstName,
          lastName,
          phoneNumber,
          email,
          source: storage.getItem('tourRequestSource') || 'DARWIN',
          inquiryId: inquiryId || '',
          selfReportedListingSource,
          selfReportedListingSourceOther,
        },
      },
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: tourRequestSchema,
  })

  return (
    <Page
      id="tour-request"
      title="Darwin Homes | Tour Request"
      description="Darwin Homes Tour Request Flow"
      className={styles.page}
    >
      {loading && <PageLoader />}
      {loadingCreateTourRequest && (
        <PageLoader
          text={
            isUnitAvailable
              ? 'Submitting your tour request...'
              : 'Adding you to the waitlist...'
          }
        />
      )}
      {error && (
        <ErrorPanel
          error={error}
          trackingInfo={{
            page: 'Tour Request Wizard Page',
            unitId,
          }}
        />
      )}
      {!hidePage && (
        <>
          <Nav />
          <Container className={styles.propertyInfo}>
            <div className={styles.info}>
              <a
                className={styles.back}
                href={`/residents/units/${slug}${search}`}
                target="_blank"
                onClick={() => {
                  track.event(
                    `tour_request_${stepEventName}_page_see_unit_details_click`
                  )
                }}
                rel="noreferrer"
              >
                {fullAddress}
              </a>
              <div className={styles.additionalInfo}>
                {petsAllowed ? (
                  <>
                    <div className={styles.iconInfo}>
                      <CheckCircleIcon className={styles.heroIcon} />
                      <span>Pets Allowed</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.iconInfo}>
                      <XCircleIcon className={styles.heroIcon} />
                      <span>Pets Not Allowed</span>
                    </div>
                  </>
                )}
                <div className={styles.iconInfo}>
                  <BedroomIcon />
                  <span>{numBedrooms} beds</span>
                </div>
                <div className={styles.iconInfo}>
                  <BathroomIcon />
                  <span>{numBathrooms} baths</span>
                </div>
                <div className={styles.iconInfo}>
                  <SqFeetIcon />
                  <span>{sqFootage} sqft</span>
                </div>
              </div>
            </div>
            <div className={styles.info}>{formatMoney(rentAmount)}</div>
          </Container>
          <div className={styles.progress}>
            <Progress
              className={styles.progress}
              strokeColor="#1d4ed8"
              percent={((index + 1) / STEPS.length) * 100}
              showInfo={false}
              strokeWidth={3}
            />
          </div>
          <Container className={styles.container}>
            <FormikProvider value={formik}>
              <Wizard>
                <Steps step={{ id: step }}>
                  <Step
                    id="listing-source"
                    render={() => (
                      <ListingSourceStep
                        stepName="listing-source"
                        setStep={setStep}
                      />
                    )}
                  />
                  <Step
                    id="move-in-date"
                    render={() => (
                      <MoveInDateStep
                        stepName="move-in-date"
                        setStep={setStep}
                        isSelfShowing={Boolean(isSelfShowing)}
                        tourRequestSource={tourRequestSource ?? ''}
                      />
                    )}
                  />
                  <Step
                    id="number-of-pets"
                    render={() => (
                      <NumberOfPetsStep
                        stepName="number-of-pets"
                        setStep={setStep}
                      />
                    )}
                  />
                  <Step
                    id="pet-categories"
                    render={() => (
                      <PetCategoryStep
                        stepName="pet-categories"
                        setStep={setStep}
                      />
                    )}
                  />
                  <Step
                    id="income"
                    render={() => (
                      <IncomeStep stepName="income" setStep={setStep} />
                    )}
                  />
                  <Step
                    id="credit-score"
                    render={() => (
                      <CreditScoreStep
                        stepName="credit-score"
                        setStep={setStep}
                      />
                    )}
                  />
                  <Step
                    id="felony"
                    render={() => (
                      <FelonyStep stepName="felony" setStep={setStep} />
                    )}
                  />
                  <Step
                    id="schedule-tour"
                    render={() => (
                      <ScheduleDayStep
                        stepName="schedule-tour"
                        setStep={setStep}
                        earliestSchedulableSelfTourDate={
                          earliestSchedulableSelfTourDate
                        }
                      />
                    )}
                  />
                  <Step
                    id="contact-info"
                    render={() => (
                      <ContactInfoStep
                        stepName="contact-info"
                        setStep={setStep}
                      />
                    )}
                  />
                  <Step
                    id="identification-document"
                    render={() => (
                      <PersonaVerificationStep
                        stepName="identification-document"
                        setStep={setStep}
                        onSubmit={handleSubmit}
                      />
                    )}
                  />
                </Steps>
              </Wizard>
            </FormikProvider>
          </Container>
        </>
      )}
    </Page>
  )
}
