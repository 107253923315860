import Container from 'components/Container'
import Page from 'pages/Page'

import styles from './NotFoundPage.module.scss'

export default function NotFoundPage(): JSX.Element {
  return (
    <Page
      title="Darwin Homes | Not found"
      hasLightBackgroundHero
      httpCode={404}
    >
      <Container className={styles.notFound}>
        <h1>Page Not Found</h1>
        <p>
          <a href="/">Go back to the home page</a>
        </p>
      </Container>
    </Page>
  )
}
