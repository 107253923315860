import gql from 'graphql-tag'

import {
  createTourRequest,
  createTourRequestVariables,
  createTourRequest_createTourRequest_tourRequest,
} from './__generated__/createTourRequest'

export type Response = createTourRequest
export type Variables = createTourRequestVariables
export type TourRequest = createTourRequest_createTourRequest_tourRequest

export const Mutation = gql`
  mutation createTourRequest($input: CreateTourRequestInput!) {
    createTourRequest(input: $input) {
      tourRequest {
        id
        publicToken
        status
        source
        isIglooLockbox
        requestedTourDate
        phoneNumber {
          id
          number
        }
        listing {
          id
          availableDate
          rentAmount
          unit {
            id
            fullAddress
            isUnitAvailable
            petsAllowed
            numBedrooms
            numBathrooms
            sqFootage
            market {
              id
              name
            }
          }
        }
      }
    }
  }
`
