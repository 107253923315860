import classNames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Subscribe } from 'unstated'

import CallUs from 'components/Header/CallUs'

import iconClose from './icon-close.svg'
import MenuItem from './MenuItem'
import MenuList from './MenuList'
import MobileMenuStateContainer from './MobileMenuStateContainer'
import styles from './styles.module.scss'

class MobileMenu extends PureComponent {
  render() {
    const { items, className } = this.props

    return (
      <Subscribe to={[MobileMenuStateContainer]}>
        {(menuState) => {
          const { open } = menuState.state
          return (
            <TransitionGroup component={null}>
              {open && (
                <CSSTransition
                  classNames={styles}
                  timeout={500}
                  onEnter={this.onEnter}
                  onExited={this.onExited}
                >
                  <div className={classNames(styles.fixedContainer, className)}>
                    <div
                      className={styles.clickTarget}
                      onClick={menuState.close}
                    />
                    <div className={styles.drawer}>
                      <img
                        src={iconClose}
                        alt="Close"
                        className={styles.close}
                        onClick={menuState.close}
                      />
                      <div className={styles.spacer} />
                      <nav className={styles.nav}>
                        <MenuList>{items.map(MenuItem.make)}</MenuList>
                      </nav>
                      <CallUs className={styles.callUs} />
                    </div>
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          )
        }}
      </Subscribe>
    )
  }

  componentWillUnmount() {
    document.body.classList.remove('dimmed', 'dimmable')
  }

  onEnter = () => {
    document.body.classList.add('dimmed', 'dimmable')
  }

  onExited = () => {
    document.body.classList.remove('dimmed', 'dimmable')
  }
}

MobileMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default MobileMenu
