import classNames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import Item from 'components/Header/MobileMenu/Item'

import styles from './styles.module.scss'

class MenuList extends PureComponent {
  render() {
    const { parent, onBack, className, children } = this.props

    return (
      <div className={classNames(styles.subMenu, className)}>
        {parent && (
          <Item
            name={parent}
            indicator="left"
            onClick={onBack}
            className={styles.header}
          />
        )}
        <div className={styles.items}>{children}</div>
      </div>
    )
  }
}

MenuList.propTypes = {
  parent: PropTypes.string,
  onBack: PropTypes.func,
  className: PropTypes.string,
}

export default MenuList
