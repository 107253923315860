import { Container } from 'unstated'

class MobileMenuStateContainer extends Container {
  state = {
    open: false,
  }

  setOpen = (open) => {
    this.setState({ open })
  }

  open = () => {
    this.setOpen(true)
  }

  close = () => {
    this.setOpen(false)
  }

  toggle = () => {
    this.setState((state) => {
      return { open: !state.open }
    })
  }
}

export default MobileMenuStateContainer
