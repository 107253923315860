import { Form, Input } from 'antd'
import PropTypes from 'prop-types'

function LockboxSerialField(props) {
  const { form, name } = props
  return (
    <Form.Item
      form={form}
      name={name}
      rules={[
        { required: true, message: 'Please enter a lockbox serial number' },
      ]}
      validateTrigger="onBlur"
    >
      <Input
        size="large"
        type="number"
        pattern="[0-9]*"
        placeholder="Serial Number"
      />
    </Form.Item>
  )
}

LockboxSerialField.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
}

LockboxSerialField.defaultProps = { name: 'lockboxSerialNumber' }

export default LockboxSerialField
