import { SVGProps } from 'react'

export default function BedroomIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 13.3574H19"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 15.6426H19"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7142 3.07129H5.28564V7.64272H16.7142V3.07129Z"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0001 17.9283V12.7854L16.7144 7.64258"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.28571 7.64258L3 12.7854V17.9283"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
