import Button from '@darwin-homes/button/Button'
import classnames from 'classnames'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'
import { PropsWithChildren, ReactNode } from 'react'

import { TourRequestForm } from 'pages/TourRequestWizardPage/TourRequestWizardPage'
import { track } from 'utils'

import styles from './Step.module.scss'

interface StepProps {
  stepLabel: string
  stepName: string
  fieldNames?: string[]
  onClickNext: () => void
  onClickBack?: () => void
  onClick?: () => void
  disableNextButton?: boolean
  stepInfo?: ReactNode
  nextText?: string
  nextEventName?: string
  backEventName?: string
  className?: string
}

export default function Step({
  stepLabel,
  stepName,
  fieldNames = [],
  onClickNext,
  onClickBack,
  onClick,
  stepInfo,
  nextText = 'Next',
  nextEventName,
  backEventName,
  disableNextButton,
  className,
  children,
}: PropsWithChildren<StepProps>): JSX.Element {
  const { setErrors, setFieldTouched, setTouched, errors } =
    useFormikContext<TourRequestForm>()
  const eventNameForStep = stepName && stepName.replace('-', '_')

  const handleClick = () => {
    onClick?.()
    handleClickNext()
  }

  const handleClickNext = () => {
    if (!fieldNames) {
      onClickNext()
      return
    }

    fieldNames.forEach((fieldName: string) =>
      setFieldTouched(fieldName, true, true)
    )

    let noErrors = true
    fieldNames.forEach((fieldName: string) => {
      if (errors[fieldName]) noErrors = false
    })

    if (noErrors) {
      track.event(
        nextEventName || `tour_request_${eventNameForStep}_page_next_click`
      )

      onClickNext()
    }
  }

  const selfReportedListingSource = storage.getItem('selfReportedListingSource')
  const showSecurityAlert =
    stepName === 'move-in-date' &&
    selfReportedListingSource &&
    ['CRAIGSLIST', 'FACEBOOK'].includes(selfReportedListingSource)

  return (
    <div className={classnames(styles.containerWrapper, className)}>
      <div className={styles.stepContainer}>
        {/* DL -TODO: 4/26/23 remove '&& false' from line below after collecting sufficient data from listing source responses */}
        {showSecurityAlert && false && (
          <div className={styles.securityAlert}>
            <div className={styles.securityAlertText}>
              <div className={styles.securityAlertHeader}>Security Alert</div>
              <div>
                Darwin does not advertise our homes on Craigslist or Facebook.
                To ensure you don’t fall victim to a leasing scam, please
                discontinue contact with all parties on Craigslist or Facebook
                immediately. Do not wire money to anyone claiming to rent you a
                home on Craigslist or Facebook.
              </div>
              <div>
                If you are interested in moving forward with the tour process
                with Darwin, please proceed with the pre-tour questionnaire, and
                we will be happy to assist!
              </div>
            </div>
          </div>
        )}
        <h1>{stepLabel}</h1>
        {stepInfo && <h2>{stepInfo}</h2>}
        {children}
        <div className={styles.buttonContainer}>
          <Button
            className={styles.btn}
            onClick={handleClick}
            disabled={disableNextButton}
          >
            {nextText}
          </Button>
          <Button
            hidden={!onClickBack}
            className={styles.btn}
            variant="secondary"
            onClick={() => {
              track.event(
                backEventName ||
                  `tour_request_${eventNameForStep}_page_back_click`
              )
              setErrors({})
              setTouched({})
              onClickBack?.()
            }}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  )
}
