import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { useParams, Redirect } from 'react-router-dom'

import Container from 'components/Container'
import ErrorPanel from 'components/ErrorPanel'
import PageLoader from 'components/PageLoader'
import Page from 'pages/Page'

import { GET_PUBLIC_TOUR_REQUEST } from './queries'
import RescheduleTourRequest from './RescheduleTourRequest'
import RescheduleTourRequestFinished from './RescheduleTourRequestFinished'
import styles from './styles.module.scss'

function RescheduleTourRequestPage(props) {
  const { isRescheduled } = props
  const { publicToken } = useParams()

  const { loading, data, error } = useQuery(GET_PUBLIC_TOUR_REQUEST, {
    variables: { publicToken },
  })

  if (loading) return <PageLoader />
  if (error) {
    return (
      <ErrorPanel
        error={error}
        trackingInfo={{
          page: 'Reschedule Tour Request Page',
          publicToken,
        }}
      />
    )
  }

  const { publicTourRequest } = data

  if (!publicTourRequest.listing.isActive) {
    return <Redirect to="/property-unavailable" />
  }

  return (
    <Page
      id="tour-request-reschedule"
      title="Darwin Homes | Reschedule Tour Request"
      description="Darwin Homes Tour Request Flow"
    >
      <Container className={styles.container}>
        {isRescheduled ? (
          <RescheduleTourRequestFinished tourRequest={publicTourRequest} />
        ) : (
          <RescheduleTourRequest
            publicToken={publicToken}
            availableDate={publicTourRequest?.listing.availableDate}
          />
        )}
      </Container>
    </Page>
  )
}

RescheduleTourRequestPage.propTypes = {
  isRescheduled: PropTypes.bool,
}

export default RescheduleTourRequestPage
