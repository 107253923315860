import FormItem from '@darwin-homes/form-item'
import Radio, { RadioGroup } from '@darwin-homes/radio'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'

import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './FelonyStep.module.scss'

export default function FelonyStep({
  setStep,
  stepName,
}: TourRequestStepProps): JSX.Element {
  const { values } = useFormikContext<TourRequestForm>()

  const handleClickNext = () => {
    const hasFelony = values.hasFelony
    storage.setItem('hasFelony', hasFelony ? 'true' : 'false')
    setStep('schedule-tour')
  }

  return (
    <Step
      stepLabel="Have you been convicted of a felony?"
      onClickNext={handleClickNext}
      onClickBack={() => setStep('credit-score')}
      stepName={stepName}
      fieldNames={['hasFelony']}
    >
      <FormItem name="hasFelony" className={styles.item}>
        <RadioGroup layout="vertical" name="hasFelony" value="hasFelony">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </FormItem>
    </Step>
  )
}
