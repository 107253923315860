import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'

import styles from './styles.module.scss'

const SIZES = {
  xsmall: '24px',
  small: '32px',
  medium: '48px',
  large: '64px',
}

function BlockLoader(props) {
  const { height, theme, size, className, text } = props

  const parentHeight = height === '100%'
  const heightStyle = parentHeight ? null : { height }
  const pixelSize = SIZES[size] || '64px'

  return (
    <>
      <div
        className={classNames({
          [styles.root]: true,
          [styles.parentHeight]: parentHeight,
          [styles.textOffset]: !!text,
        })}
        style={heightStyle}
      >
        <ReactLoading
          type="spin"
          className={className}
          color={theme === 'dark' ? '#ffffff' : '#c2c2c2'}
          height={pixelSize}
          width={pixelSize}
        />
      </div>
      {text && (
        <div className={classNames(styles.root, styles.text)}>{text}</div>
      )}
    </>
  )
}

BlockLoader.propTypes = {
  height: PropTypes.string,
  theme: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  className: PropTypes.string,
  text: PropTypes.string,
}

BlockLoader.defaultProps = {
  size: 'large',
}

export default BlockLoader
