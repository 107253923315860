import Button from '@darwin-homes/button'
import { useHistory } from 'react-router-dom'

import Nav from 'components/Nav'
import Page from 'pages/Page'

import styles from './AccessSuccessPage.module.scss'

export default function AccessSuccessPage(): JSX.Element {
  const history = useHistory()

  return (
    <Page
      title="Darwin Homes | Access Success"
      description="Tour Request Access Success"
      classname={styles.page}
    >
      <Nav />
      <div className={styles.container}>
        <div className={styles.success}>
          <div>Code Sent.</div>
          <div>Enjoy your tour!</div>
        </div>
        <div className={styles.successCopy}>
          We have sent the unlock code to you via text message. Please check
          your text for a code.
        </div>
        <div className={styles.tip}>
          <div className={styles.tipTitle}>Next Steps</div>
          <ul className={styles.tipList}>
            <li>
              If you would like to apply for the home after the tour, please
              submit your applications via{' '}
              <a href="/residents/rentals">
                www.darwinhomes.com/residents/rentals
              </a>
              , the only trusted site to apply for a Darwin home.
            </li>
            <li>
              Do not wire money or pay anyone with gift cards. Darwin will never
              ask you to pay outside of our resident portal.
            </li>
          </ul>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant="tertiary"
            className={styles.btn}
            onClick={() => history.push('/access/tour')}
          >
            Didn&apos;t receive the code? Resend code
          </Button>
        </div>
      </div>
    </Page>
  )
}
