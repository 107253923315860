import Checkbox from '@darwin-homes/checkbox'
import FormItem from '@darwin-homes/form-item'
import { XCircleIcon } from '@heroicons/react/solid'
import { useFormikContext, ErrorMessage } from 'formik'
import storage from 'local-storage-fallback'
import { useEffect, useState } from 'react'

import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './PetCategoryStep.module.scss'

export default function PetCategoryStep({
  setStep,
  stepName,
}: TourRequestStepProps): JSX.Element {
  const { errors, touched, values, setFieldTouched } =
    useFormikContext<TourRequestForm>()
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setFieldTouched('hasPets', true, true)
  }, [])

  const handleClickNext = () => {
    storage.setItem('hasDog', values.hasDog ? 'true' : 'false')
    storage.setItem('hasCat', values.hasCat ? 'true' : 'false')
    storage.setItem('hasBird', values.hasBird ? 'true' : 'false')
    storage.setItem('hasLivestock', values.hasLivestock ? 'true' : 'false')
    storage.setItem('hasReptile', values.hasReptile ? 'true' : 'false')
    storage.setItem('hasRodent', values.hasRodent ? 'true' : 'false')
    storage.setItem('hasOther', values.hasOther ? 'true' : 'false')
    setStep('income')
  }

  return (
    <Step
      stepLabel="What type of pets do you own?"
      stepInfo="Select all that apply"
      onClickNext={handleClickNext}
      onClick={() => setShowError(true)}
      onClickBack={() => setStep('number-of-pets')}
      stepName={stepName}
      fieldNames={['hasPets']}
    >
      <FormItem name="hasDog" className={styles.item}>
        <Checkbox
          label="Dog"
          checked={values.hasDog}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      <FormItem name="hasCat" className={styles.item}>
        <Checkbox
          label="Cat"
          checked={values.hasCat}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      <FormItem name="hasBird" className={styles.item}>
        <Checkbox
          label="Bird"
          checked={values.hasBird}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      <FormItem name="hasLivestock" className={styles.item}>
        <Checkbox
          label="Livestock"
          checked={values.hasLivestock}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      <FormItem name="hasReptile" className={styles.item}>
        <Checkbox
          label="Reptile"
          checked={values.hasReptile}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      <FormItem name="hasRodent" className={styles.item}>
        <Checkbox
          label="Rodent"
          checked={values.hasRodent}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      <FormItem name="hasOther" className={styles.item}>
        <Checkbox
          label="Other"
          checked={values.hasOther}
          wrapperClass={styles.checkbox}
        />
      </FormItem>
      {showError && touched['hasPets'] && Boolean(errors['hasPets']) && (
        <div className={styles.errorMessage}>
          <XCircleIcon />
          <ErrorMessage name={'hasPets'} component="div" />
        </div>
      )}
    </Step>
  )
}
