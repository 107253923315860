import gql from 'graphql-tag'

export const GET_PUBLIC_TOUR_REQUEST = gql`
  query publicTourRequest($publicToken: String!) {
    publicTourRequest(publicToken: $publicToken) {
      id
      publicToken
      listing {
        id
        isActive
        availableDate
        unit {
          id
          fullAddress
        }
      }
      requestedTourDate
      phoneNumber {
        id
        number
      }
    }
  }
`
