import { SVGProps } from 'react'

export default function BathroomIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.09082 17.5459V19.1823"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9092 17.5459V19.1823"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.8184V13.4547C2 15.7137 3.83191 17.5456 6.09091 17.5456H15.9091C18.1681 17.5456 20 15.7137 20 13.4547V11.8184H2V11.8184Z"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 11.8184V5.2729C19.9999 3.91718 18.9011 2.81836 17.5454 2.81836C16.1896 2.81836 15.0908 3.91718 15.0908 5.2729"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5453 7.72701H12.6362C12.6362 6.37128 13.735 5.27246 15.0908 5.27246C16.4465 5.27246 17.5453 6.37128 17.5453 7.72701V7.72701Z"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
