import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import styles from 'components/Footer/FooterNav/styles.module.css'
import MenuLink from 'components/Menu/MenuLink'

class SubMenu extends PureComponent {
  render() {
    const { menu, pathname } = this.props
    const items = menu.items

    return (
      <div className={styles.subMenu}>
        <p>{menu.menuTitle}</p>
        <div className={styles.subMenuItems}>
          {items.map((item) => (
            <MenuLink
              key={item.menuTitle}
              title={item.menuTitle}
              path={item.path}
              trackingId={item.trackingId}
              isToNewLanding={item.isToNewLanding}
              pathname={pathname}
              theme={styles}
            />
          ))}
        </div>
      </div>
    )
  }
}

SubMenu.propTypes = {
  menu: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default SubMenu
