import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { ReactComponent as LogoDark } from 'assets/darwin-logo-dark.svg'
import { ReactComponent as Logo } from 'assets/darwin-logo-white.svg'
import Container from 'components/Container'
import LocationsHeader from 'components/LocationsHeader'
import MenuLink from 'components/Menu/MenuLink'
import PageConfigStateContainer from 'pages/Page/PageConfigStateContainer'
import { isMobile } from 'utils'
import { pathIncludesOneOf } from 'utils/path'
import withStateContainer from 'utils/withStateContainer'

import CallUs from './CallUs'
import HamburgerButton from './HamburgerButton'
import MobileMenu from './MobileMenu'
import MobileMenuStateContainer from './MobileMenu/MobileMenuStateContainer'
import styles from './styles.module.css'
import SubMenu from './SubMenu'

function Header(props) {
  const { menu, pageConfig, mobileMenuState } = props
  const location = useLocation()
  const { pathname } = location
  const [isSnapped, setSnapped] = useState(false)
  const [isMobileScreen, setMobileScreen] = useState(isMobile())

  const handleScroll = useCallback(() => {
    const snapped = window.scrollY > 80
    setSnapped(snapped)
  }, [setSnapped])

  const updateDimensions = useCallback(() => {
    const mobile = isMobile()
    setMobileScreen((prevMobile) => {
      const mobileStateChanged = mobile !== prevMobile
      // If mobile state changed, then force closing the menu. Otherwise leave it as it is
      if (mobileStateChanged) {
        mobileMenuState.close()
      }
      return mobile
    })
  }, [mobileMenuState, setMobileScreen])

  // onMount & onUnmount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', updateDimensions)
    }
  }, [handleScroll, updateDimensions])

  // On update
  useEffect(() => {
    // Close menu when navigating to a different page
    if (isMobileScreen) {
      mobileMenuState.close()
    }
  }, [mobileMenuState, pathname, isMobileScreen])

  const {
    hasLightBackgroundHero,
    mobileNavbarColor,
    lightMobileNavBar,
    logoReplacement,
  } = pageConfig.state
  const headerBg =
    isMobileScreen && mobileNavbarColor
      ? { backgroundColor: mobileNavbarColor }
      : {}

  const lightBackground =
    (!isMobileScreen || (isMobileScreen && lightMobileNavBar)) &&
    (isSnapped || hasLightBackgroundHero)

  const classes = classnames({
    [styles.header]: true,
    [styles.snapped]: isSnapped && !isMobileScreen,
    [styles.lightBg]: lightBackground,
    [styles.desktop]: !isMobileScreen,
    [styles.bottomBorder]: lightMobileNavBar && isMobileScreen,
  })

  function renderMenuItem(item) {
    if (item.items) {
      return <SubMenu menu={item} pathname={pathname} key={item.menuTitle} />
    } else {
      return (
        <MenuLink
          key={item.menuTitle}
          title={item.menuTitle}
          path={item.path}
          trackingId={item.trackingId}
          isToNewLanding={item.isToNewLanding}
          pathname={pathname}
          theme={styles}
        />
      )
    }
  }

  const pathsToExclude = [
    '/tour-request',
    '/reschedule-tour-request',
    '/access/',
  ]

  if (pathIncludesOneOf(pathname, pathsToExclude)) {
    return null
  }

  if (pathname.includes('tour')) {
    return <LocationsHeader hideCallUs />
  }

  const LogoComponent = lightBackground ? LogoDark : Logo

  return (
    <div className={classes} style={headerBg}>
      <Container className={styles.wrapper}>
        {(logoReplacement && (
          <div className={styles.logoReplacement}>{logoReplacement}</div>
        )) || (
          <a href="/">
            <LogoComponent className={styles.logo} />
          </a>
        )}
        {isMobileScreen ? (
          <MobileMenu items={menu} className={styles.mobileMenu} />
        ) : (
          <nav className={styles.nav}>
            {menu.map(renderMenuItem)}
            <CallUs />
          </nav>
        )}
      </Container>
      {isMobileScreen && (
        <HamburgerButton
          onToggle={() => mobileMenuState.toggle()}
          inverted={lightMobileNavBar}
        />
      )}
    </div>
  )
}

Header.propTypes = {
  menu: PropTypes.array.isRequired,
  hasLightBackgroundHero: PropTypes.bool,
  hasInvertedButton: PropTypes.bool,
  mobileNavbarColor: PropTypes.string,
  lightMobileNavBar: PropTypes.bool,
  logoReplacement: PropTypes.node,
}

Header.defaultProps = {
  hasLightBackgroundHero: false,
  hasInvertedButton: false,
  mobileNavbarColor: null,
  lightMobileNavBar: false,
  logoReplacement: null,
}

export default withStateContainer(Header, {
  mobileMenuState: MobileMenuStateContainer,
  pageConfig: PageConfigStateContainer,
})
