import gql from 'graphql-tag'

import { unlockForVendorVariables } from './__generated__/unlockForVendor'

export type Variables = unlockForVendorVariables

export const Mutation = gql`
  mutation unlockForVendor($input: UnlockForVendorInput!) {
    unlockForVendor(input: $input) {
      success
    }
  }
`
