import gql from 'graphql-tag'

export const GET_PUBLIC_UNIT = gql`
  query publicUnit($slug: String!) {
    publicUnit(slug: $slug) {
      id
      fullAddress
      numBedrooms
      numBathrooms
      sqFootage
      petsAllowed
      matterportUrl
      isUnitAvailable
      activeListing {
        id
        availableDate
        rentAmount
        isSelfShowing
      }
      market {
        id
        name
      }
    }
  }
`
