import PropTypes from 'prop-types'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { track } from 'utils'
import { normalizeError } from 'utils/error'

import styles from './styles.module.css'

function ErrorPanel(props) {
  const { message, description, error, trackingInfo } = props

  useDeepCompareEffect(() => {
    track.event('landing_page_error', trackingInfo)
  }, [trackingInfo])

  const {
    message: normalizedMessage,
    description: normalizedDescription,
    additionalErrors,
  } = normalizeError(error)
  const errorMessage = message || normalizedMessage
  const errorDescription = description || normalizedDescription

  return (
    <div className={styles.root}>
      <h2>Oops... Something unexpected happened!</h2>
      {errorMessage && <h3>{errorMessage}</h3>}
      {errorDescription && (
        <p className={styles.description}>{errorDescription}</p>
      )}
      {additionalErrors &&
        additionalErrors.map((err, i) => <p key={i}>{err}</p>)}
      <p>
        You may report that problem by reaching out to our support team:{' '}
        <a href="mailto:leasing@darwinhomes.com">leasing@darwinhomes.com</a>
      </p>
    </div>
  )
}

ErrorPanel.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.object,
  trackingInfo: PropTypes.object,
}

export default ErrorPanel
