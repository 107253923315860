import _ from 'lodash'
import { Subscribe, Container } from 'unstated'

export default function withStateContainer(WrappedComponent, StateContainer) {
  return function Wrapped(props) {
    let subscriptions
    const stateContainerPropNames = []
    if (_.isArray(StateContainer)) {
      subscriptions = StateContainer
    } else if (StateContainer.prototype instanceof Container) {
      subscriptions = [StateContainer]
    } else {
      subscriptions = []
      for (const key in StateContainer) {
        if (Object.getOwnPropertyDescriptor(StateContainer, key)) {
          subscriptions.push(StateContainer[key])
          stateContainerPropNames.push(key)
        }
      }
    }

    return (
      <Subscribe to={subscriptions}>
        {(...containers) => {
          const stateContainerProps = {}
          if (stateContainerPropNames.length > 0) {
            containers.forEach((container, i) => {
              const containerName = stateContainerPropNames[i]
              stateContainerProps[containerName] = container
            })
          }

          return (
            <WrappedComponent
              stateContainer={containers[0]}
              stateContainers={containers}
              {...props}
              {...stateContainerProps}
            />
          )
        }}
      </Subscribe>
    )
  }
}
