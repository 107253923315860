import { Container } from 'unstated'

class PageConfigStateContainer extends Container {
  state = {
    hasLightBackgroundHero: false,
    hasInvertedButton: false,
    mobileNavbarColor: null,
    callToActionFormId: null,
    lightMobileNavBar: false,
    logoReplacement: null,
  }
}

export default PageConfigStateContainer
