import { useLocation } from 'react-router-dom'

import useEffectOnUpdate from 'hooks/useEffectOnUpdate'
import usePrevious from 'hooks/usePrevious'

function ScrollToTop(props) {
  const location = useLocation()
  const previousLocation = usePrevious(location)

  const { pathname, hash, search } = location

  useEffectOnUpdate(() => {
    if (!previousLocation) return

    if (
      pathname !== previousLocation.pathname ||
      search !== previousLocation.search ||
      (previousLocation.hash && !hash)
    ) {
      window.scrollTo(0, 0)
    }
  })

  return props.children
}

export default ScrollToTop
