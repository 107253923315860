import gql from 'graphql-tag'

import {
  getPublicTourRequest,
  getPublicTourRequestVariables,
} from './__generated__/getPublicTourRequest'

export type Response = getPublicTourRequest

export type Variables = getPublicTourRequestVariables

export const Query = gql`
  query getPublicTourRequest($publicToken: String!) {
    publicTourRequest(publicToken: $publicToken) {
      id
      userId
      listing {
        id
        isActive
        unit {
          id
          market {
            id
            name
          }
        }
      }
    }
  }
`
