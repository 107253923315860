import { Button } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import { ReactComponent as TourScheduled } from 'assets/tour-scheduled.svg'

import styles from './styles.module.scss'

function RescheduleTourRequestFinished(props) {
  const { tourRequest } = props

  const { requestedTourDate, listing } = tourRequest

  return (
    <div className={styles.rescheduled}>
      <TourScheduled />
      <div className={styles.rescheduleTour}>
        Your tour has been rescheduled!
      </div>
      <div className={styles.tour}>
        Your tour at {listing.unit.fullAddress} is scheduled for{' '}
        {moment(requestedTourDate).format('ll')}. Please note that you can tour
        this home anytime between 8AM-8PM. We have also sent you a text message
        with further instructions.
      </div>
      <div className={styles.buttonContainer}>
        <a href="/residents/rentals">
          <Button size="large" type="primary" className={styles.button}>
            See more homes
          </Button>
        </a>
      </div>
    </div>
  )
}

RescheduleTourRequestFinished.propTypes = {
  tourRequest: PropTypes.shape({
    publicToken: PropTypes.string.isRequired,
    requestedTourDate: PropTypes.string.isRequired,
    listing: PropTypes.shape({
      unit: PropTypes.shape({
        fullAddress: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
}

export default RescheduleTourRequestFinished
