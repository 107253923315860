import storage from 'local-storage-fallback'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import PageLoader from 'components/PageLoader'

export default function InquiryLandingPage() {
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    storage.setItem('firstName', searchParams.get('firstName') ?? '')
    storage.setItem('lastName', searchParams.get('lastName') ?? '')
    storage.setItem('email', searchParams.get('email') ?? '')

    const phoneNumber = searchParams.get('phoneNumber')
    const annualIncomeAmount = searchParams.get('annualIncomeAmount')
    const creditScore = searchParams.get('creditScore')
    const numPets = searchParams.get('numPets')
    const hasDog = searchParams.get('hasDog')
    const hasCat = searchParams.get('hasCat')
    const hasBird = searchParams.get('hasBird')
    const hasLivestock = searchParams.get('hasLivestock')
    const hasReptile = searchParams.get('hasReptile')
    const hasRodent = searchParams.get('hasRodent')
    const hasOther = searchParams.get('numPets')
    const channel = searchParams.get('channel')
    const selfReportedListingSource = searchParams.get(
      'selfReportedListingSource'
    )
    const selfReportedListingSourceOther = searchParams.get(
      'selfReportedListingSourceOther'
    )

    if (phoneNumber) storage.setItem('phoneNumber', phoneNumber)
    if (annualIncomeAmount)
      storage.setItem('annualIncomeAmount', annualIncomeAmount)
    if (creditScore) storage.setItem('creditScore', creditScore)
    if (numPets) storage.setItem('numPets', numPets)
    if (hasDog) storage.setItem('hasDog', hasDog)
    if (hasCat) storage.setItem('hasCat', hasCat)
    if (hasBird) storage.setItem('hasBird', hasBird)
    if (hasLivestock) storage.setItem('hasLivestock', hasLivestock)
    if (hasReptile) storage.setItem('hasReptile', hasReptile)
    if (hasRodent) storage.setItem('hasRodent', hasRodent)
    if (hasOther) storage.setItem('hasOther', hasOther)
    if (channel) storage.setItem('tourRequestSource', channel)
    if (selfReportedListingSource)
      storage.setItem('selfReportedListingSource', selfReportedListingSource)
    if (selfReportedListingSourceOther)
      storage.setItem(
        'selfReportedListingSourceOther',
        selfReportedListingSourceOther
      )

    storage.setItem(
      'applicantWebLoginToken',
      searchParams.get('applicantWebLoginToken') ?? ''
    )

    const slug = searchParams.get('unitSlug')
    const redirect = searchParams.get('redirect')
    const market = searchParams.get('market')

    switch (redirect) {
      case 'UNIT_DETAILS':
        history.push(`/units/${slug}?market=${market}`)
        break
      case 'LISTINGS':
        window.location.assign(`/residents/rentals?market=${market}`)
        break
      case 'TOUR_REQUEST':
      default:
        history.push(`/tour-request/inquiry/${slug}?market=${market}`)
        break
    }
  }, [searchParams, history])

  return <PageLoader />
}
