import gql from 'graphql-tag'

import {
  createPublicTourFeedbackResponse,
  createPublicTourFeedbackResponseVariables,
} from './__generated__/createPublicTourFeedbackResponse'

export type Response = createPublicTourFeedbackResponse

export type Variables = createPublicTourFeedbackResponseVariables

export const Mutation = gql`
  mutation createPublicTourFeedbackResponse(
    $input: CreatePublicTourFeedbackResponseInput!
  ) {
    createPublicTourFeedbackResponse(input: $input) {
      success
    }
  }
`
