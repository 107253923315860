import _ from 'lodash'

import { ErrorCodes } from 'constants/errors'

export function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => {
    if (Array.isArray(fieldsError[field])) {
      // Handle nested fields
      return fieldsError[field].some((f) =>
        _.isPlainObject(f) ? hasErrors(f) : f !== undefined
      )
    } else {
      return fieldsError[field]
    }
  })
}

export function displayFormErrors({ form, errorContainer, formErrors } = {}) {
  if (!form) return
  if (!formErrors) {
    if (!errorContainer) return
    formErrors = errorContainer.getError()
  }
  if (!formErrors || formErrors.code !== ErrorCodes.INVALID_INPUT) return

  const { getFieldInstance, getFieldError, setFields } = form
  const fields = []
  for (const field in formErrors.info) {
    if (Object.prototype.hasOwnProperty.call(formErrors.info, field)) {
      const messages = formErrors.info[field]
      if (messages) {
        const errors = messages instanceof Array ? messages : [messages]
        const instance = getFieldInstance(field)
        if (instance) {
          const existingErrors = getFieldError(field)
          fields.push({
            name: field,
            errors: _.uniq([...existingErrors, ...errors]),
          })
        }
      }
    }
  }
  if (fields.length) {
    setFields(fields)
    scrollToFirstFieldError({ form, fields })
  }
  if (errorContainer) {
    errorContainer.clearError()
  }
}

export function scrollToFirstFieldError({ form, fields }) {
  const { getFieldsValue, scrollToField } = form

  const allFields = getFieldsValue()
  const firstErrorField = Object.keys(allFields).find((field) =>
    fields.some((fieldInstance) => fieldInstance.name === field)
  )

  if (firstErrorField) {
    scrollToField(firstErrorField)
  }
}
