import classnames from 'classnames'
import { useLocation } from 'react-router-dom'

import styles from 'components/Header/styles.module.css'
import { track } from 'utils'

import { ReactComponent as HeadsetIcon } from './headset-icon.svg'

function CallUs({ className }) {
  const handleClick = () => {
    track.event('cta_click_to_call')
  }

  const location = useLocation()

  let phoneNumber = '18333279461'
  let displayNumber = '1-833-DARWIN-1'
  const { pathname } = location
  if (pathname.includes('austin')) {
    phoneNumber = '5128724900'
    displayNumber = '(512) 872-4900'
  } else if (pathname.includes('houston')) {
    phoneNumber = '8329247609'
    displayNumber = '(832) 924-7609'
  } else if (pathname.includes('dallas') || pathname.includes('fort-worth')) {
    phoneNumber = '9728539464'
    displayNumber = '(972) 853-9464'
  } else if (pathname.includes('san-antonio')) {
    phoneNumber = '210-879-8909'
    displayNumber = '(210) 879-8909'
  } else if (pathname.includes('cleveland') || pathname.includes('akron')) {
    phoneNumber = '216-238-2095'
    displayNumber = '(216) 238-2095'
  }

  const displayIcon = pathname.includes('cities')

  return (
    <div className={classnames(styles.callUs, className)}>
      {displayIcon && <HeadsetIcon />}
      <a href={'tel:' + phoneNumber} onClick={handleClick}>
        <span className={styles.phoneNumber}>{displayNumber}</span>
      </a>
    </div>
  )
}

export default CallUs
