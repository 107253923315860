import { useMutation } from '@apollo/react-hooks'
import Button from '@darwin-homes/button'
import { notification } from 'antd'
import { useState } from 'react'

import { TourRequest } from 'pages/TourRequestWizardPage/graphql/mutations/createTourRequest.graphql'
import { handleGraphQLErrors } from 'utils/error'

import { RESEND_TOUR_REQUEST_SMS } from './graphql/mutations/resendTourRequestSms.graphql'
import styles from './ResendTextButton.module.scss'

interface ResendTextButtonProps {
  tourRequest: TourRequest
}

export default function ResendTextButton({
  tourRequest,
}: ResendTextButtonProps): JSX.Element {
  const [textResent, setTextResent] = useState(false)

  const { publicToken } = tourRequest

  const [resendTourRequestSms, { loading }] = useMutation(
    RESEND_TOUR_REQUEST_SMS,
    {
      onCompleted: (data) => {
        notification.success({
          message: `Text message sent to ${data.resendTourRequestSms.tourRequest.phoneNumber.number}.`,
        })

        setTextResent(true)
      },
      onError: (error) =>
        handleGraphQLErrors({
          ...error,
          form: undefined,
          defaultMessage:
            'An error occurred. Please contact leasing@darwinhomes.com for help.',
          checkCode: false,
        }),
    }
  )

  async function handleSubmit() {
    try {
      await resendTourRequestSms({
        variables: {
          input: {
            publicToken,
          },
        },
      })

      setTextResent(true)
    } catch {
      // Catch block left intentionally empty
    }
  }

  return (
    <Button
      variant="secondary"
      type="submit"
      onClick={handleSubmit}
      className={styles.resendButton}
      loading={loading}
      disabled={loading || textResent}
    >
      {textResent ? 'Text resent!' : "Didn't receive a text? Resend!"}
    </Button>
  )
}
