import DatePicker from '@darwin-homes/datepicker'
import { XCircleIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import { useFormikContext, ErrorMessage } from 'formik'
import storage from 'local-storage-fallback'
import { useEffect } from 'react'

import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'
import { getDateString } from 'utils/date'

import styles from './MoveInDateStep.module.scss'

interface MoveInDateStepProps extends TourRequestStepProps {
  isSelfShowing: boolean
  tourRequestSource: string
}

export default function MoveInDateStep({
  setStep,
  stepName,
}: MoveInDateStepProps): JSX.Element {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<TourRequestForm>()

  useEffect(() => {
    const today = new Date()
    if (dayjs(values.moveInStartDate).isBefore(dayjs(today))) {
      setFieldValue('moveInStartDate', dayjs(today).format('YYYY-MM-DD'))
    }
    if (dayjs(values.moveInEndDate).isBefore(dayjs(today))) {
      setFieldValue('moveInEndDate', dayjs(today).format('YYYY-MM-DD'))
    }
  }, [])

  const getStartDate = () => {
    if (values.moveInStartDate) return dayjs(values.moveInStartDate).toDate()

    return null
  }

  const getEndDate = () => {
    if (values.moveInEndDate) return dayjs(values.moveInEndDate).toDate()

    return null
  }

  const onDateRangeChange = (dates: Date | [Date, Date]) => {
    if (Array.isArray(dates)) {
      setFieldValue('moveInStartDate', getDateString(dates[0]))

      if (dates.length === 2) {
        setFieldValue('moveInEndDate', getDateString(dates[1]))
      }
    }
  }

  const handleClickNext = () => {
    storage.setItem('moveInStartDate', values.moveInStartDate ?? '')
    storage.setItem('moveInEndDate', values.moveInEndDate ?? '')

    setStep('number-of-pets')
  }

  return (
    <Step
      stepLabel="What is your desired move in date?"
      stepInfo="Please select the date range of when you are able to move in"
      onClickNext={handleClickNext}
      onClickBack={() => setStep('listing-source')}
      stepName={stepName}
      fieldNames={['moveInEndDate']}
    >
      <div className={styles.container}>
        <DatePicker
          selectsRange
          onChange={onDateRangeChange}
          startDate={getStartDate()}
          endDate={getEndDate()}
          minDate={new Date()}
        />
        {touched['moveInEndDate'] && Boolean(errors['moveInEndDate']) && (
          <div className={styles.errorMessage}>
            <XCircleIcon />
            <ErrorMessage name={'moveInEndDate'} component="div" />
          </div>
        )}
      </div>
    </Step>
  )
}
