import { useQuery, useMutation } from '@apollo/react-hooks'
import { CheckIcon } from '@heroicons/react/outline'
import { Button } from 'antd'
import storage from 'local-storage-fallback'
import { useParams } from 'react-router-dom'

import PageLoader from 'components/PageLoader'
import Page from 'pages/Page'
import * as CreateTourFeedback from 'pages/TourFeedback/graphql/mutations/createTourFeedback.graphql'
import * as GetPublicTourRequest from 'pages/TourFeedback/graphql/queries/getTourRequest.graphql'
import IconHeader from 'pages/TourFeedback/IconHeader'
import ListingBanner from 'pages/TourFeedback/ListingBanner'
import { track } from 'utils'

import styles from './TourFeedbackFinishedPage.module.scss'

export default function TourFeedbackFinishedPage(): JSX.Element {
  const { publicToken } = useParams<{ publicToken: string }>()
  const isTourPositive = storage.getItem('isTourPositive')

  const { loading, data, error } = useQuery<
    GetPublicTourRequest.Response,
    GetPublicTourRequest.Variables
  >(GetPublicTourRequest.Query, {
    variables: { publicToken },
  })

  const [createTourFeedback] = useMutation<
    CreateTourFeedback.Response,
    CreateTourFeedback.Variables
  >(CreateTourFeedback.Mutation)

  if (!data?.publicTourRequest)
    return (
      <Page>
        <PageLoader />
      </Page>
    )
  const { listing, userId } = data.publicTourRequest
  const { isActive, unit, id: listingId } = listing
  const trackProperties = { listingId, publicToken, userId }

  const marketUrl = `/residents/rentals${
    unit.market ? `?market=${unit.market.name}` : ''
  }`

  const startApplication = async () => {
    await createTourFeedback({
      variables: {
        input: { tourRequestToken: publicToken, isTourPositive: false },
      },
    })

    track.event(
      'user_started_application_from_positive_tour_feedback',
      trackProperties
    )

    window.open(
      `${process.env.REACT_APP_APPLICANT_PORTAL_URI}/pre-apply/${listingId}/validate-email`
    )
  }

  const activeListingNegativeFeedback = (
    <>
      <div className={styles.text}>Still interested in this home?</div>
      <Button
        size="large"
        type="primary"
        shape="round"
        onClick={startApplication}
        disabled={Boolean(loading || error)}
        loading={loading}
      >
        Apply Now
      </Button>
      <div>
        or{` `}
        <span
          onClick={() => {
            track.event(
              'view_more_homes_from_negative_tour_feedback',
              trackProperties
            )
            storage.removeItem('isTourPositive')
          }}
        >
          <a href={marketUrl}>View More Homes</a>
        </span>
      </div>
    </>
  )
  const activeListingPositiveFeedback = (
    <>
      <div className={styles.text}>Looking for another home in the area?</div>
      <Button
        size="large"
        type="primary"
        shape="round"
        onClick={() => {
          storage.removeItem('isTourPositive')
          track.event(
            'view_more_homes_from_positive_tour_feedback',
            trackProperties
          )
          window.location.assign(marketUrl)
        }}
        disabled={Boolean(loading || error)}
        loading={loading}
      >
        View More Homes
      </Button>
    </>
  )
  const activeListing =
    isTourPositive == 'true'
      ? activeListingPositiveFeedback
      : activeListingNegativeFeedback

  const inactiveListing = (
    <>
      <div className={styles.text}>
        Looks like this home just left the market!
      </div>
      <Button
        size="large"
        type="primary"
        shape="round"
        onClick={() => {
          track.event(
            'tour_feedback_submitted_for_inactive_listing',
            trackProperties
          )
          storage.removeItem('isTourPositive')
          window.location.assign(marketUrl)
        }}
        disabled={Boolean(loading || error)}
        loading={loading}
      >
        View More Homes
      </Button>
    </>
  )

  return (
    <Page
      title="Darwin Homes | Tour Feedback"
      description="Tour Feedback"
      lightMobileNavBar={true}
    >
      <ListingBanner listingId={listingId} />
      <IconHeader icon={CheckIcon} title="Thank you for your feedback" />
      <div className={styles.container}>
        {isActive ? activeListing : inactiveListing}
      </div>
    </Page>
  )
}
