import Checkbox from '@darwin-homes/checkbox/Checkbox'
import FormItem from '@darwin-homes/form-item'
import Input from '@darwin-homes/input'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'
import { useEffect, useState } from 'react'

import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './ContactInfoStep.module.scss'

export default function ContactInfoStep({
  setStep,
  stepName,
}: TourRequestStepProps) {
  const { values, setFieldTouched } = useFormikContext<TourRequestForm>()

  const [isTermsAccepted, setIsTermsAccepted] = useState(false)

  useEffect(() => {
    setFieldTouched('firstName', true, true)
    setFieldTouched('lastName', true, true)
    setFieldTouched('email', true, true)
    setFieldTouched('phoneNumber', true, true)
  }, [])

  const handleClickNext = () => {
    storage.setItem('firstName', values.firstName ?? '')
    storage.setItem('lastName', values.lastName ?? '')
    storage.setItem('email', values.email ?? '')
    storage.setItem('phoneNumber', values.phoneNumber ?? '')
    setStep('identification-document')
  }

  return (
    <Step
      stepLabel="Contact Information"
      stepInfo="Your information would only be used to contact you regarding your tour. We won't share your personal information."
      onClickNext={handleClickNext}
      onClickBack={() => setStep('schedule-tour')}
      stepName={stepName}
      nextText="Next"
      fieldNames={['firstName', 'lastName', 'email', 'phoneNumber']}
      disableNextButton={!isTermsAccepted}
    >
      <FormItem name="firstName" className={styles.item}>
        <Input placeholder="First Name" />
      </FormItem>
      <FormItem name="lastName" className={styles.item}>
        <Input placeholder="Last Name" />
      </FormItem>
      <FormItem name="email" className={styles.item}>
        <Input placeholder="Email" type="email" />
      </FormItem>
      <FormItem name="phoneNumber" className={styles.item}>
        <Input type="tel" placeholder="Phone Number" />
      </FormItem>
      <div className={styles.tip}>
        <FormItem name="hasAcceptedTerms" className={styles.item}>
          <Checkbox
            label="By providing my phone number, I consent to receive text messages
          regarding Darwin Homes services. Text messaging rates may apply."
            checked={isTermsAccepted}
            onClick={() => setIsTermsAccepted(!isTermsAccepted)}
            wrapperClass={styles.checkbox}
          />
        </FormItem>
      </div>
    </Step>
  )
}
