import _ from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'

import banner from 'assets/darwin-sharing-banner.jpg'
import { track, baseUrl } from 'utils'
import withStateContainer from 'utils/withStateContainer'

import PageConfigStateContainer from './PageConfigStateContainer'
import PageReferrerContainer from './PageReferrerContainer'

class Page extends PureComponent {
  componentDidMount() {
    const {
      hasLightBackgroundHero,
      hasInvertedButton,
      mobileNavbarColor,
      pageConfig,
      lightMobileNavBar,
      logoReplacement,
      location: { search },
    } = this.props
    pageConfig.setState({
      hasLightBackgroundHero,
      hasInvertedButton,
      mobileNavbarColor,
      lightMobileNavBar,
      logoReplacement,
    })
    if (search) {
      const params = queryString.parse(search)
      const { gclid } = params || {}
      if (gclid) {
        track.setGoogleClickId(gclid)
      }
    }
  }

  componentWillUnmount() {
    const { location, referrerState } = this.props
    referrerState.setReferrerLocation(location)
  }

  render() {
    const {
      title,
      description,
      className,
      id,
      location,
      canonicalPath,
      httpCode,
    } = this.props

    const base = baseUrl()
    let canonical = null
    if (httpCode === 200 && location) {
      canonical = base + (canonicalPath || location.pathname)
    }

    const bannerUrl = base + banner

    return (
      <div id={id} className={className}>
        <Helmet onChangeClientState={this.handleTracking}>
          {title && <title>{title}</title>}
          {title && <meta property="og:title" content={title} />}
          {description && <meta name="description" content={description} />}
          {description && (
            <meta property="og:description" content={description} />
          )}
          {canonical && <link rel="canonical" href={canonical} />}
          {canonical && <meta property="og:url" content={canonical} />}
          {httpCode !== 200 && (
            <meta name="prerender-status-code" content={httpCode} />
          )}
          <meta property="og:image" content={bannerUrl} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
        {this.props.children}
      </div>
    )
  }

  handleTracking = () => {
    let { title, trackingPage, trackingParams, referrerState } = this.props

    if (!trackingParams) {
      trackingParams = {}
    }
    trackingParams.referrer = referrerState.getReferrer()

    const pageName = trackingPage || _.snakeCase(title)
    track.page(pageName, { title, ...trackingParams })
  }
}

Page.defaultProps = {
  title: null,
  description: null,
  hasLightBackgroundHero: false,
  hasInvertedButton: false,
  mobileNavbarColor: null,
  logoReplacement: null,
  httpCode: 200,
}

Page.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalPath: PropTypes.string,
  hasLightBackgroundHero: PropTypes.bool,
  hasInvertedButton: PropTypes.bool,
  mobileNavbarColor: PropTypes.string,
  httpCode: PropTypes.number,
  trackingPage: PropTypes.string,
  trackingParams: PropTypes.object,
  lightMobileNavBar: PropTypes.bool,
  logoReplacement: PropTypes.node,
}

export default withStateContainer(withRouter(Page), {
  pageConfig: PageConfigStateContainer,
  referrerState: PageReferrerContainer,
})
