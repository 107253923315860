import storage from 'local-storage-fallback'

import usePersona from 'hooks/usePersona'
import Step from 'pages/TourRequestWizardPage/Step'

import styles from './PersonaVerificationStep.module.scss'

interface PersonaVerificationStepProps {
  setStep: (step: string) => void
  stepName: string
  onSubmit: () => void
}

const handlePersonaExit = (inquiryId: string, onSubmit: () => void) => {
  storage.setItem('inquiryId', inquiryId)
  onSubmit()
}

export default function PersonaVerificationStep({
  setStep,
  stepName,
  onSubmit,
}: PersonaVerificationStepProps) {
  const client = usePersona(
    process.env.REACT_APP_TOUR_REQUEST_PERSONA_TEMPLATE_ID,
    {
      referenceId: storage.getItem('phoneNumber'),
      onComplete: ({ inquiryId }: { inquiryId: string }) => handlePersonaExit(inquiryId, onSubmit),
      onError: ({ inquiryId }: { inquiryId: string }) => handlePersonaExit(inquiryId, onSubmit),
    }
  )

  return (
    <Step
      stepLabel="Identity Verification"
      stepInfo="In order to ensure that we provide a safe and enjoyable experience for you, we need to verify your identity"
      onClickNext={() => client.open()}
      onClickBack={() => setStep('contact-info')}
      nextText="Let's get started"
      stepName={stepName}
    >
      <div className={styles.tip}>
        <div className={styles.tipTitle}>What to Expect</div>
        <ul className={styles.tipList}>
          <li>
            Please have a copy of your government issued ID (driver&apos;s
            license, state issued ID, passport, etc)
          </li>
          <li>
            Be prepared to verify that you&apos;re really you by using facial
            biometrics
          </li>
        </ul>
      </div>
    </Step>
  )
}
