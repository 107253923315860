import { PropsWithChildren } from 'react'

import styles from './BannerCard.module.scss'

interface BannerCardProps {
  title: string
}

export default function BannerCard({
  title,
  children,
}: PropsWithChildren<BannerCardProps>): JSX.Element {
  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>{title}</div>
      <div className={styles.columnContainer}>{children}</div>
    </div>
  )
}
