import Logo from 'assets/darwin-logo-dark.svg'
import Container from 'components/Container'
import CallUs from 'components/Header/CallUs'

import styles from './styles.module.css'

const LocationsHeader = (props) => {
  const { hideCallUs } = props

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.brand}>
          <a href="/">
            <img alt="Darwin Homes Logo" className={styles.logo} src={Logo} />
          </a>
          <div className={styles.title} />
        </div>
        {!hideCallUs && <CallUs className={styles.call} />}
      </Container>
    </div>
  )
}

export default LocationsHeader
