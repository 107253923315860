import FormItem from '@darwin-homes/form-item'
import Input from '@darwin-homes/input'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'

import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './CreditScoreStep.module.scss'

export default function CreditScoreStep({
  setStep,
  stepName,
}: TourRequestStepProps): JSX.Element {
  const { values } = useFormikContext<TourRequestForm>()

  const handleClickNext = () => {
    const creditScore = values.creditScore
    storage.setItem('creditScore', creditScore)
    setStep('felony')
  }

  return (
    <Step
      stepLabel="What is your credit score?"
      stepInfo="If you are living with others, please estimate the average credit score of everyone over the age of 18"
      onClickNext={handleClickNext}
      onClickBack={() => setStep('income')}
      stepName={stepName}
      fieldNames={['creditScore']}
    >
      <FormItem name="creditScore" className={styles.credit}>
        <Input placeholder="700" type="number" />
      </FormItem>
    </Step>
  )
}
