import { useRef, useEffect } from 'react'

function useEffectOnUpdate(callback) {
  // https://reactjs.org/docs/hooks-faq.html#can-i-run-an-effect-only-on-updates
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      return callback()
    }
  })
}

export default useEffectOnUpdate
