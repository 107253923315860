import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import MenuLink from 'components/Menu/MenuLink'
import { track } from 'utils'

import LinkedIn from './linkedin.svg'
import styles from './styles.module.css'
import SubMenu from './SubMenu'

class FooterNav extends PureComponent {
  trackLinkedIn = () => {
    track.event('clicked_linkedin')
  }

  render() {
    const { location } = this.props
    const { pathname } = location

    function renderMenuItem(item) {
      if (item.items) {
        return <SubMenu menu={item} pathname={pathname} key={item.menuTitle} />
      } else {
        return (
          <MenuLink
            key={item.menuTitle}
            title={item.menuTitle}
            path={item.path}
            trackingId={item.trackingId}
            isToNewLanding={item.isToNewLanding}
            pathname={pathname}
            theme={styles}
          />
        )
      }
    }

    return (
      <div className={styles.container}>
        <div className={styles.nav}>
          {this.props.menu.map(renderMenuItem)}
          <div className={styles.link}>
            <a
              href="https://www.linkedin.com/company/darwin-homes-inc/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.trackLinkedIn}
            >
              <img alt="" className="linkedin" src={LinkedIn} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

FooterNav.propTypes = {
  menu: PropTypes.array.isRequired,
}

export default withRouter(FooterNav)
