import gql from 'graphql-tag'

import { unlockForSelfTourVerifiedLocationVariables } from './__generated__/unlockForSelfTourVerifiedLocation'

export type Variables = unlockForSelfTourVerifiedLocationVariables

export const Mutation = gql`
  mutation unlockForSelfTourVerifiedLocation(
    $input: UnlockForSelfTourVerifiedLocationInput!
  ) {
    unlockForSelfTourVerifiedLocation(input: $input) {
      success
    }
  }
`
