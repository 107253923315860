import { SVGProps } from 'react'

export default function SqFeetIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 19L3 13L19 13L19 19L3 19Z"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13L16 15"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13L13 15"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13L10 15"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13L7 15"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="3.5"
        y1="7.5"
        x2="3.5"
        y2="2.5"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="18.5"
        y1="7.5"
        x2="18.5"
        y2="2.5"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="18.5"
        y1="5.5"
        x2="3.5"
        y2="5.5"
        stroke="#1F2220"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
