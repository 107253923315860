import gql from 'graphql-tag'

export const RESCHEDULE_TOUR_REQUEST = gql`
  mutation rescheduleTourRequest($input: RescheduleTourRequestInput!) {
    rescheduleTourRequest(input: $input) {
      tourRequest {
        id
        publicToken
        requestedTourDate
      }
    }
  }
`
