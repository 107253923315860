import classNames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import { track } from 'utils'

class MenuLink extends PureComponent {
  render() {
    const { path, theme, title, pathname, isToNewLanding } = this.props
    const internal = path.startsWith('/')

    if (!path) {
      return (
        <div className={classNames(theme.link, theme.comingSoon)}>{title}</div>
      )
    } else if (isToNewLanding) {
      return (
        <a href={path} className={theme.link}>
          {title}
        </a>
      )
    } else if (internal) {
      return (
        <NavLink
          key={path}
          to={path}
          location={pathname ? { pathname } : undefined}
          exact
          activeClassName={theme.selected}
          className={theme.link}
        >
          {title}
        </NavLink>
      )
    } else {
      return (
        <a
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          className={theme.link}
          onClick={this.onExternalLinkClick}
        >
          {title}
        </a>
      )
    }
  }

  onExternalLinkClick = () => {
    const { trackingId, onExternalLink } = this.props
    if (trackingId) {
      track.event(trackingId)
    }
    if (onExternalLink) {
      onExternalLink()
    }
  }
}

MenuLink.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  theme: PropTypes.object,
  onExternalLink: PropTypes.func,
  trackingId: PropTypes.string,
  isToNewLanding: PropTypes.bool,
}

export default MenuLink
