import { Container } from 'unstated'

import { baseUrl } from 'utils'

class PageReferrerContainer extends Container {
  state = {
    referrer: document.referrer,
  }

  setReferrerLocation = (location) => {
    const { pathname, hash, search } = location
    let url = baseUrl() + pathname
    if (search) {
      url += search
    }
    if (hash) {
      url += hash
    }
    this.setReferrer(url)
  }

  setReferrer = (referrer) => {
    this.setState({ referrer })
  }

  getReferrer = () => {
    return this.state.referrer
  }
}

export default PageReferrerContainer
