import FormItem from '@darwin-homes/form-item'
import Input from '@darwin-homes/input'
import { useFormikContext } from 'formik'
import storage from 'local-storage-fallback'

import Step from 'pages/TourRequestWizardPage/Step'
import {
  TourRequestForm,
  TourRequestStepProps,
} from 'pages/TourRequestWizardPage/TourRequestWizardPage'

import styles from './NumberOfPetsStep.module.scss'

export default function NumberOfPetsStep({
  setStep,
  stepName,
}: TourRequestStepProps): JSX.Element {
  const { values } = useFormikContext<TourRequestForm>()

  const handleClickNext = () => {
    const numPets = values.numPets
    storage.setItem('numPets', numPets)
    parseInt(numPets) > 0 ? setStep('pet-categories') : setStep('income')
  }

  return (
    <Step
      stepLabel="How many pets do you own?"
      stepInfo="Please include you and your roommates' pets"
      onClickNext={handleClickNext}
      onClickBack={() => setStep('move-in-date')}
      stepName={stepName}
      fieldNames={['numPets']}
    >
      <FormItem name="numPets" className={styles.pets}>
        <Input placeholder="0" type="number" />
      </FormItem>
    </Step>
  )
}
