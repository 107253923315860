import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

function HamburgerButton(props) {
  const { onToggle } = props

  const toggle = () => {
    if (onToggle) {
      props.onToggle()
    }
  }

  const { inverted, active } = props

  const classes = classNames({
    hamburger: true,
    'hamburger--spin': true,
    [styles.hamburger]: true,
    [styles.inverted]: inverted,
    'is-active': active,
  })

  return (
    <button className={classes} type="button" onClick={toggle}>
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  )
}

HamburgerButton.propTypes = {
  active: PropTypes.bool,
  inverted: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
}

HamburgerButton.defaultProps = {
  active: false,
  inverted: false,
}

export default HamburgerButton
