import { useQuery } from '@apollo/react-hooks'
import {
  ArchiveIcon,
  CalendarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  HomeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PhotographIcon,
} from '@heroicons/react/outline'
import dayjs from 'dayjs'
import capitalize from 'lodash/capitalize'
import { useState } from 'react'

import { AmenityCategory } from '__generated__/globalTypes'
import { ReactComponent as Paw } from 'assets/paw.svg'
import bannerStyles from 'pages/TourFeedback/ListingBanner/BannerCard/BannerCard.module.scss'
import { formatMoney } from 'utils/money'

import BannerCard from './BannerCard'
import * as GetListingBanner from './graphql/queries/getListingBanner.graphql'
import styles from './ListingBanner.module.scss'

interface ListingBannerProps {
  listingId: string | undefined
}

export default function ListingBanner({
  listingId = '',
}: ListingBannerProps): JSX.Element | null {
  const [showDetails, setShowDetails] = useState(false)

  const handleClick = () => setShowDetails(!showDetails)

  const { data, loading } = useQuery<
    GetListingBanner.Response,
    GetListingBanner.Variables
  >(GetListingBanner.Query, {
    variables: { id: listingId },
    skip: !listingId,
  })

  const { publicListing } = data || {}
  const { unit, previewImageUrl } = publicListing || {}
  const {
    fullAddress,
    numBathrooms,
    numBedrooms,
    sqFootage,
    petsAllowed,
    catsAllowed,
    smallDogsAllowed,
    largeDogsAllowed,
    maxPetsAllowed,
    activeListing,
    depositAmount,
    amenities,
    minLeaseTermMonths,
    maxLeaseTermMonths,
  } = unit || {}

  const { rentAmount, availableDate, applicationFeeAmount } =
    activeListing || {}

  const allowedPets = capitalize(
    [
      ['cats', catsAllowed],
      ['small dogs', smallDogsAllowed],
      ['large dogs', largeDogsAllowed],
    ]
      .filter((pet) => pet[1] === true)
      .map((pet) => pet[0])
      .join(', ')
  )

  const maxPetsText = maxPetsAllowed ? `(${maxPetsAllowed} max)` : ''
  const petsText =
    petsAllowed && maxPetsAllowed
      ? `${allowedPets} ${maxPetsText}`
      : 'No Pets Allowed'

  const availableText =
    availableDate && dayjs().isBefore(dayjs(availableDate))
      ? `Available ${dayjs(availableDate).format('L')}`
      : 'Available now'

  const boundedTermText = maxLeaseTermMonths
    ? `${minLeaseTermMonths || '0'} - ${maxLeaseTermMonths} months`
    : `Min ${minLeaseTermMonths || ''} months`

  const leaseTermText =
    !minLeaseTermMonths && !maxLeaseTermMonths
      ? 'Variable lease term'
      : boundedTermText

  if (loading || !data) return null

  return (
    <>
      <div className={styles.bannerWrapper}>
        <div className={styles.banner}>
          <div>
            <div className={styles.address}>{fullAddress}</div>
            <div className={styles.subBanner}>
              <div>
                <ArchiveIcon /> {numBedrooms}
                {numBedrooms && numBedrooms > 1 ? ' beds' : ' bed'}
                <ArchiveIcon />
                {numBathrooms}
                {numBathrooms && numBathrooms > 1 ? ' baths' : ' bath'}
              </div>
              <div>
                <ArchiveIcon className={styles.sqFt} />
                {sqFootage} sqft
              </div>
              <div>
                <Paw /> {petsText}
              </div>
            </div>
          </div>
          <div className={styles.rentBlock}>
            <div>{formatMoney(rentAmount)}</div>
            <div onClick={handleClick} className={styles.expandBlock}>
              {showDetails ? (
                <div>
                  Less Detail <ChevronUpIcon />
                </div>
              ) : (
                <div>
                  More Detail <ChevronDownIcon />
                </div>
              )}
            </div>
          </div>
        </div>
        {showDetails && (
          <div className={styles.expandedBanner}>
            {previewImageUrl ? (
              <div className={styles.imgWrapper}>
                <img src={previewImageUrl} />
              </div>
            ) : (
              <PhotographIcon className={styles.cameraIcon} />
            )}
            <BannerCard title="Property Details">
              <div className={bannerStyles.column}>
                <div className={bannerStyles.subtitle}>Lease Details</div>
                <div>
                  <ClockIcon /> {availableText}
                </div>
                <div>
                  <CalendarIcon /> {leaseTermText}
                </div>
                <div>
                  <CurrencyDollarIcon />
                  {formatMoney(depositAmount)} security deposit
                </div>
                <div>
                  <CurrencyDollarIcon />{' '}
                  {applicationFeeAmount
                    ? `${formatMoney(applicationFeeAmount)} Application Fee`
                    : 'No Application Fee'}
                </div>
              </div>
              <div className={bannerStyles.column}>
                <div className={bannerStyles.subtitle}>Animal Policy</div>
                <div>
                  <Paw /> $300/animal deposit
                </div>
                <div>
                  <Paw /> {petsText}
                </div>
              </div>
            </BannerCard>
            <BannerCard title="Features">
              <div className={bannerStyles.column}>
                <div className={bannerStyles.subtitle}>Appliances</div>
                {amenities?.map((appliance) =>
                  appliance.category === AmenityCategory.APPLIANCE ? (
                    <div key={appliance.id}>
                      <HomeIcon />
                      {appliance.name}
                    </div>
                  ) : null
                )}
              </div>
              <div className={bannerStyles.column}>
                <div className={bannerStyles.subtitle}>Amenities</div>
                {amenities?.map((amenity) =>
                  amenity.category !== AmenityCategory.APPLIANCE ? (
                    <div key={amenity.id}>
                      <HomeIcon />
                      {amenity.name}
                    </div>
                  ) : null
                )}
              </div>
            </BannerCard>
          </div>
        )}
      </div>
    </>
  )
}
