import { useMutation, useQuery } from '@apollo/react-hooks'
import { ChatIcon, SparklesIcon } from '@heroicons/react/outline'
import { Form, Button, Checkbox, Input, notification } from 'antd'
import storage from 'local-storage-fallback'
import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import PageLoader from 'components/PageLoader'
import Page from 'pages/Page'
import * as CreateTourFeedback from 'pages/TourFeedback/graphql/mutations/createTourFeedback.graphql'
import * as GetPublicTourRequest from 'pages/TourFeedback/graphql/queries/getTourRequest.graphql'
import IconHeader from 'pages/TourFeedback/IconHeader'
import ListingBanner from 'pages/TourFeedback/ListingBanner'
import FeedbackButton from 'pages/TourFeedback/TourFeedbackPage/FeedbackButton'
import { track } from 'utils'

import styles from './TourFeedbackPage.module.scss'

const convertInput = (feedbackArray: string[]) => {
  return feedbackArray.reduce((acc, val) => {
    return { [val]: true, ...acc }
  }, {})
}

export default function TourFeedbackPage(): JSX.Element {
  const { publicToken } = useParams<{ publicToken: string }>()
  const history = useHistory()
  const [form] = Form.useForm()

  const { loading, data, error } = useQuery<
    GetPublicTourRequest.Response,
    GetPublicTourRequest.Variables
  >(GetPublicTourRequest.Query, {
    variables: { publicToken },
  })

  const [createTourFeedback] = useMutation<
    CreateTourFeedback.Response,
    CreateTourFeedback.Variables
  >(CreateTourFeedback.Mutation)

  const [isTourPositive, setIsTourPositive] = useState<boolean | undefined>(
    undefined
  )
  const [isNotApplying, setIsNotApplying] = useState(false)

  useEffect(() => track.event('tour_feedback_page_viewed', trackProperties), [])

  if (!data?.publicTourRequest)
    return (
      <Page>
        <PageLoader />
      </Page>
    )

  const { listing, userId } = data.publicTourRequest
  const { isActive, unit, id: listingId } = listing
  const trackProperties = { listingId, publicToken, userId }

  async function handleSubmit() {
    try {
      const values = await form.validateFields()
      const { feedbackOptions, additionalComments } = values
      const checkboxes = convertInput(feedbackOptions)
      const input = {
        tourRequestToken: publicToken,
        isTourPositive,
        additionalComments,
        ...checkboxes,
      }

      await createTourFeedback({
        variables: {
          input,
        },
      })

      track.event('tour_feedback_sent', { input, listingId })
      storage.setItem('isTourPositive', String(isTourPositive))
      history.push(`/tour-feedback/${publicToken}/finished`)
    } catch (error) {
      notification.error({
        message: 'Could not send tour feedback',
      })
    }
  }

  function startApplication() {
    void createTourFeedback({
      variables: {
        input: { tourRequestToken: publicToken, isTourPositive },
      },
    })

    track.event(
      'user_started_application_from_positive_tour_feedback',
      trackProperties
    )
    window.open(
      `${process.env.REACT_APP_APPLICANT_PORTAL_URI}/pre-apply/${listingId}/validate-email`
    )
  }

  const badTourFeedbackForm = (
    <Form form={form} className={styles.formContainer}>
      <Form.Item
        name="feedbackOptions"
        rules={[
          { required: true, message: 'Please select at least one option' },
        ]}
      >
        <Checkbox.Group className={styles.checkboxes}>
          <Checkbox value="isLockboxIssue">Code didn’t work</Checkbox>
          <Checkbox value="isKeyMissing">Missing key</Checkbox>
          <Checkbox value="isHomeDirty">Needs cleaning</Checkbox>
          <Checkbox value="isPropertyDamage">Property damage</Checkbox>
          <Checkbox value="isPriceTooHigh">Price too high</Checkbox>
          <Checkbox value="isUndesirableLocation">
            Undesirable location
          </Checkbox>
          <Checkbox value="isNotPetFriendly">Not pet friendly </Checkbox>
          <Checkbox value="other">Other </Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item name="additionalComments">
        <Input.TextArea
          rows={4}
          placeholder="Help us get it right. Tell us what went wrong..."
        />
      </Form.Item>
      <Button
        size="large"
        type="primary"
        shape="round"
        onClick={handleSubmit}
        disabled={Boolean(loading || error)}
        loading={loading}
      >
        Send Feedback
      </Button>
    </Form>
  )

  const goodTourOptions = (
    <>
      {isActive ? (
        <Button
          size="large"
          type="primary"
          shape="round"
          onClick={startApplication}
          loading={loading}
        >
          Start Application
        </Button>
      ) : (
        <>
          <div className={styles.text}>This home has just left the market!</div>
          <Button
            size="large"
            type="primary"
            shape="round"
            onClick={() => {
              track.event('view_more_homes_from_tour_feedback', trackProperties)
              window.location.assign(
                `/residents/rentals${
                  unit.market ? `?market=${unit.market.name}` : ''
                }`
              )
            }}
            disabled={Boolean(loading || error)}
            loading={loading}
          >
            View More Homes
          </Button>
        </>
      )}
      <div className={styles.noThanks} onClick={() => setIsNotApplying(true)}>
        No thanks
      </div>
    </>
  )

  return (
    <Page
      title="Darwin Homes | Tour Feedback"
      description="Tour Feedback"
      lightMobileNavBar={true}
    >
      <ListingBanner listingId={listingId} />
      {!isNotApplying && (
        <>
          <IconHeader
            icon={ChatIcon}
            title="Tour Feedback"
            subtitle="We’d love to hear all about it!"
          />
          <div className={styles.form}>
            <div className={styles.title}>How was your tour?</div>
            <div className={styles.buttonBlock}>
              <FeedbackButton
                type="bad"
                isTourPositive={isTourPositive}
                handleClick={() => {
                  setIsTourPositive(false)
                  track.event('tour_feedback_is_negative', trackProperties)
                }}
              />
              <FeedbackButton
                type="good"
                isTourPositive={isTourPositive}
                handleClick={() => {
                  setIsTourPositive(true)
                  track.event('tour_feedback_is_positive', trackProperties)
                }}
              />
            </div>
            {isTourPositive == false && badTourFeedbackForm}
            {isTourPositive && goodTourOptions}
          </div>
        </>
      )}
      {isNotApplying && (
        <>
          <IconHeader
            icon={SparklesIcon}
            title="Not ready?"
            subtitle="We get it - some things take time"
          />
          <Form form={form} className={styles.formContainer}>
            <Form.Item
              name="feedbackOptions"
              rules={[
                {
                  required: true,
                  message: 'Please select at least one option',
                },
              ]}
            >
              <Checkbox.Group className={styles.checkboxes}>
                <Checkbox value="isStillLooking">
                  Still looking at other homes
                </Checkbox>
                <Checkbox value="isPriceTooHigh">Price too high</Checkbox>
                <Checkbox value="isUndesirableLocation">
                  Undesirable location
                </Checkbox>
                <Checkbox value="isMissingAmenities">
                  Missing features/amenities
                </Checkbox>
                <Checkbox value="hasChangedMind">Change of plans</Checkbox>
                <Checkbox value="isUnhappyWithRentalRequirements">
                  Dislike rental requirements
                </Checkbox>
                <Checkbox value="isNotPetFriendly">Not pet friendly</Checkbox>
                <Checkbox value="other">Other</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="additionalComments">
              <Input.TextArea rows={4} placeholder="Share your thoughts..." />
            </Form.Item>
            <Button
              size="large"
              type="primary"
              shape="round"
              onClick={handleSubmit}
              disabled={Boolean(loading || error)}
              loading={loading}
            >
              Send Feedback
            </Button>
          </Form>
        </>
      )}
    </Page>
  )
}
