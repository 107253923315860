import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import styles from 'components/Header/styles.module.css'
import MenuLink from 'components/Menu/MenuLink'

class SubMenu extends PureComponent {
  render() {
    const { pathname, menu } = this.props
    const items = menu.items
    const pathElements = pathname.split('#')
    const path = pathElements && pathElements.length > 0 ? pathElements[0] : ''
    const menuContainsCurrentPage = !_.isUndefined(
      _.find(items, (item) => item.path === path)
    )

    return (
      <div
        className={classNames({
          [styles.subMenu]: true,
          [styles.selected]: menuContainsCurrentPage,
        })}
      >
        <p>{menu.menuTitle}</p>
        <div className={styles.subMenuItemsWrapper}>
          <div className={styles.subMenuItems}>
            {items.map((item) => (
              <MenuLink
                key={item.menuTitle}
                title={item.menuTitle}
                path={item.path}
                trackingId={item.trackingId}
                isToNewLanding={item.isToNewLanding}
                pathname={pathname}
                theme={styles}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

SubMenu.propTypes = {
  menu: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default SubMenu
