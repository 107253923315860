import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Subscribe } from 'unstated'

import Item from 'components/Header/MobileMenu/Item'
import MenuList from 'components/Header/MobileMenu/MenuList'
import MobileMenuStateContainer from 'components/Header/MobileMenu/MobileMenuStateContainer'

import styles from './styles.module.scss'

class MenuItem extends PureComponent {
  constructor(props) {
    super(props)

    const { children, location } = this.props
    const { pathname } = location

    let selected = false
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return
      if (selected) return

      const { link } = element.props

      if (link === pathname) {
        selected = true
      }
    })

    this.state = {
      open: selected,
      selected: selected,
    }
  }

  render() {
    const { name, link, comingSoon, trackingId, children, isToNewLanding } =
      this.props
    const { open, selected } = this.state

    return (
      <Subscribe to={[MobileMenuStateContainer]}>
        {(menuState) => {
          return (
            <>
              <Item
                name={name}
                link={link}
                trackingId={trackingId}
                comingSoon={comingSoon}
                indicator={children && 'right'}
                onClick={children && this.toggleOpen}
                onExternalLink={() => menuState.close()}
                selected={selected}
                isToNewLanding={isToNewLanding}
              />
              <TransitionGroup component={null}>
                {open && (
                  <CSSTransition classNames={styles} timeout={500}>
                    <MenuList
                      className={styles.subMenu}
                      parent={name}
                      onBack={this.toggleOpen}
                    >
                      {children}
                    </MenuList>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </>
          )
        }}
      </Subscribe>
    )
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }
}

function makeItem(item) {
  const { menuTitle, path, items, trackingId, isToNewLanding } = item
  return (
    <WrappedMenuItem
      key={menuTitle}
      name={menuTitle}
      isToNewLanding={isToNewLanding}
      link={path}
      trackingId={trackingId}
      comingSoon={!path && (!items || items.length === 0)}
    >
      {items && items.map(makeItem)}
    </WrappedMenuItem>
  )
}

MenuItem.make = makeItem

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  trackingId: PropTypes.string,
  comingSoon: PropTypes.bool,
  isToNewLanding: PropTypes.bool,
}

const WrappedMenuItem = withRouter(MenuItem)

export default WrappedMenuItem
